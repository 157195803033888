/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import axios from 'axios';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../CONSTS/ENDPOINTS';

const functions = getFunctions();
const useEbayAPI = (region: string) => {
  const [accessToken, setAccessToken] = useState('');
  const [apiResponse, setApiResponse] = useState(null);
  const [error, setError] = useState('');
  const getOAuthToken = async () => {
    if (!accessToken) return;
    try {
      const response = await axios.get('http://localhost:3001/get-ebay-token');
      setAccessToken(response.data.accessToken);
      console.log('Access Token:', response.data.accessToken);
    } catch (err) {
      setError('Failed to obtain access token');
      console.error(
        'Error getting token:',
        (err as any).response
          ? (err as any).response.data
          : (err as any).message,
      );
    }
  };

  const searchEbay = async (query = 'pets') => {
    try {
      const response = await axios.get<{ itemSummaries: any[] }>(
        `http://localhost:3001/search-ebay?query=${encodeURIComponent(query)}`,
      );

      return response?.data?.itemSummaries || [];
    } catch (err) {
      setError('Failed to make API request');
      console.error(
        'Error making API request:',
        (err as any).response
          ? (err as any).response.data
          : (err as any).message,
      );
      return null;
    }
  };

  const searchEbayByImage = async (imageUrl: string) => {
    console.log('searchEbayByImage', imageUrl, region);
    if (!imageUrl) return;
    try {
      const search = httpsCallableFromURL(
        functions,
        firebaseCall('searchEbayWithImage'),
      );
      const response: any = await search({
        imageUrl,
        region,
      });
      console.log('API Response IMAGE Ebay:', response.data);

      return response?.data?.itemSummaries;
    } catch (err) {
      setError('Failed to make API request');
      console.error(
        'Error making API request:',
        (err as any).response
          ? (err as any).response.data
          : (err as any).message,
      );
      return null;
    }
  };

  const getBestSeller = async (sellerUsername: string) => {
    try {
      const response = await axios.get(
        'http://localhost:3001/seller-top-product',
        {
          params: {
            sellerUsername: 'peters309',
          },
        },
      );
      console.log('BEST SEELLERRR', response.data);
    } catch (err) {
      setError('Failed to obtain access token');
      console.error(
        'Error making API request:',
        (err as any).response
          ? (err as any).response.data
          : (err as any).message,
      );
    }
  };

  return {
    getOAuthToken,
    searchEbay,
    searchEbayByImage,
    accessToken: !!accessToken?.length,
    apiResponse,
    getBestSeller,
  };
};

export default useEbayAPI;
