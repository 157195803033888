import { Container, Paper } from '@mui/material';
import React from 'react';
import Box from './Box';
import SPACES from '../utils/CONSTS/SPACES';

interface PageWrapperProps {
  children: React.ReactNode;
  center?: boolean;
}

const PageWrapper = ({ children, center = false }: PageWrapperProps) => {
  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Container fixed>
        <Box
          minWidth={1000}
          pt={SPACES.SPACE_L}
          center
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: center ? 'center' : 'flex-start',
            alignItems: center ? 'center' : 'flex-start',
          }}
        >
          {children}
        </Box>
      </Container>
    </Paper>
  );
};

export default PageWrapper;
