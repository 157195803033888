export const SURVEY_TYPE = [
  {
    name: 'RICS Home Survey – Level 2 (survey only and valuation)',
    value: 'level2',
  },
  {
    name: 'RICS Home Survey – Level 3',
    value: 'level3',
  },
];
