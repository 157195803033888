import * as React from 'react';
import Box from './Box';
import COLORS from '../utils/CONSTS/COLORS';
import { helix } from 'ldrs';
import { Paper } from '@mui/material';

helix.register();

// Default values shown

interface LoadingProps {
  size?: number;
  height?: string | number;
}

const Loading = ({ size, height }: LoadingProps) => {
  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Box center flex column height={height ?? '80vh'}>
        <span>
          <l-helix
            size={size}
            stroke-length="0.25"
            bg-opacity="0.1"
            speed="0.9"
            color={COLORS?.PRIMARY}
          />
        </span>
      </Box>
    </Paper>
  );
};

export default Loading;
