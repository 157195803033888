/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  type DocumentData,
  doc,
  getDoc,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  and,
  type DocumentSnapshot,
} from 'firebase/firestore';
import { type UserInfo } from 'firebase/auth';
import PageWrapper from '../../components/PageWrapper';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ACCOUNT_TYPE } from '../../utils/CONSTS/SUPERADMINTERMS';
import Box from '../../components/Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { Edit } from '@mui/icons-material';
import COLORS from '../../utils/CONSTS/COLORS';
import { LoadingButton } from '@mui/lab';

const db = getFirestore();

const userDetail = {};

const ManageUsers = () => {
  const [user, setUser] = useState<any>(null);
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const componentWidth = { width: 400 };
  const getUser = async () => {
    try {
      setLoading(true);
      // find organisation
      const ref = collection(db, 'users');
      const q = query(ref, where('email', '==', email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((_doc: DocumentSnapshot) => {
        setUser({ uid: _doc?.id, ..._doc.data() });
      });
    } catch (error) {
      console.log('error', error);
    }
    setLoading(false);
  };

  console.log('UUUUSSER', user);

  return (
    <PageWrapper center>
      <Typography variant="h6">Find A User to Edit</Typography>
      <Typography color={COLORS.TEXT_LIGHT} width={400} textAlign={'center'}>
        Search for the user you are wishing to edit with their full email
        address
      </Typography>

      <Box mt={SPACES.SPACE_L}>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={componentWidth}
        />
      </Box>
      <Box mt={20}>
        <LoadingButton variant="outlined" onClick={getUser} loading={loading}>
          Find User
        </LoadingButton>
      </Box>

      {!!user && (
        <Box mt={SPACES.SPACE_M}>
          <Box row vCenter>
            <Box>
              <Box row>
                <Typography fontWeight={700}>Name:</Typography>
                <Box ml={SPACES.SPACE_S}>
                  <Typography>{user?.email}</Typography>
                </Box>
              </Box>
              <Box row>
                <Typography fontWeight={700}>HQ Name:</Typography>
                <Box ml={SPACES.SPACE_S}>
                  <Typography>{user?.organisationName ?? 'Not Set'}</Typography>
                </Box>
              </Box>
            </Box>
            <Box ml={SPACES.SPACE_L}>
              <Button
                onClick={() => {
                  navigate(`/user/edit/${user?.uid}`);
                }}
                endIcon={<Edit />}
              >
                Edit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </PageWrapper>
  );
};

export default ManageUsers;
