import CryptoJS from 'crypto-js';

// Encryption and Decryption key
const secretKey: string = 'JADKFVMMPPAD_JADFJKAHDOFJAODSF_AJD';
// Arrow function to encrypt a message
const encrypt = (message: string): string => {
  const ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
  return ciphertext.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Making it URL-safe
};

// Arrow function to decrypt a message
const decrypt = (ciphertext: string | undefined): string => {
  if (!ciphertext) return ''; // return empty string if ciphertext is undefined
  const restoredCiphertext = ciphertext.replace(/-/g, '+').replace(/_/g, '/');
  const bytes = CryptoJS.AES.decrypt(restoredCiphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export default {
  encrypt,
  decrypt,
};

// // Original message
// const originalString: string = 'URgSIYtiUeWKEa0RT5ypZnnngxA2';

// // Encrypt the message
// const encryptedString: string = encrypt(originalString);
// console.log('Encrypted:', encryptedString);

// // Decrypt the message
// const decryptedString: string = decrypt(encryptedString);
// console.log('Decrypted:', decryptedString);
