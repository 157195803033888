/* eslint-disable max-len */
import React from 'react';
import { compact, merge } from 'lodash';
import { type CSSProperties } from 'react';

// import LinearGradient from 'react-native-linear-gradient';

// import { PRIMARY_GRADIENT } from '../../consts/COLOURS';
// import { SHADOW } from '../../consts/SHADOW';

export interface BoxProps {
  animated?: boolean;
  shadow?: boolean;
  lightShadow?: boolean;
  darkShadow?: boolean;
  children?: React.ReactNode;
  center?: boolean;
  vCenter?: boolean;
  hCenter?: boolean;
  textCenter?: boolean;
  selfCenter?: boolean;
  spaceBetween?: boolean;
  flex?: boolean | number;
  flexGrow?: boolean | number;
  flexWrap?: string;
  row?: boolean;
  mAll?: number | null;
  mt?: number | false;
  mb?: number | false;
  mh?: number | false;
  mv?: number | false;
  ml?: number | false;
  mr?: number | false;
  pAll?: number | false;
  pv?: number | false;
  ph?: number | false;
  pl?: number | false;
  pt?: number | false;
  pr?: number | false;
  pb?: number | false;
  width?: number | string | false;
  minWidth?: number | string | false;
  height?: number | string | false;
  borderRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  borderTopLeftRadius?: number | false;
  borderTopRightRadius?: number | false;
  justifyContent?: string | false;
  alignItems?: string | false;
  backgroundColor?: string | false;
  aspectRatio?: number;
  borderWidth?: number | false;
  borderColor?: string | false;
  absolute?: boolean;
  top?: number | string;
  bottom?: number | string;
  left?: number | string;
  right?: number | string;
  opacity?: number;
  zIndex?: number;
  style?: Array<null | undefined> | CSSProperties | null;
  onPress?: (() => void) | null;
  activeOpacity?: number;
  hGradient?: boolean;
  vGradient?: boolean;
  gradientColors?: string[];
  gradientStartBalance?: number;
  gradientEndBalance?: number;
  disabled?: boolean;
  overflow?: string;
  position?: string;
  fadeIn?: boolean;
  fadeInTime?: number;
  fadeInDelay?: number;
  slideIn?: boolean;
  slideInTime?: number;
  slideInDelay?: number;
  onClick?: (() => void) | null;
  column?: boolean;
  maxWidth?: number | string | false;
  minHeight?: number | string | false;
  className?: string;
  relative?: boolean;
  key?: string;
}

export const Box = ({
  animated,
  shadow,
  lightShadow,
  darkShadow,
  children,
  center,
  textCenter,
  vCenter,
  hCenter,
  selfCenter,
  spaceBetween,
  flex,
  flexGrow,
  flexWrap,
  row,
  column,
  mAll,
  mt,
  mb,
  mh,
  mv,
  ml,
  mr,
  pAll,
  pv,
  ph,
  pl,
  pt,
  pr,
  pb,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  borderRadius,
  borderBottomLeftRadius,
  borderBottomRightRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  justifyContent,
  alignItems,
  backgroundColor,
  aspectRatio,
  borderWidth,
  borderColor,
  absolute,
  top,
  bottom,
  left,
  right,
  opacity,
  zIndex,
  style,
  onClick,
  activeOpacity,
  hGradient,
  vGradient,
  gradientColors,
  gradientStartBalance,
  gradientEndBalance,
  disabled,
  overflow,
  fadeIn,
  fadeInTime,
  fadeInDelay,
  slideIn,
  slideInTime,
  slideInDelay,
  className,
  relative,
  ...restProps
}: BoxProps) => {
  const styleTemplate = compact([
    !!flex && { display: 'flex' },
    !!flexGrow && { flexGrow: flexGrow === true ? 1 : flexGrow },
    !!flexWrap && { flexWrap },
    center && styles.center,
    textCenter && { textAlign: 'center' },
    hCenter && styles.hCenter,
    vCenter && styles.vCenter,
    selfCenter && styles.selfCenter,
    spaceBetween && styles.spaceBetween,
    row && styles.row,
    column && { flexDirection: 'column' },
    !!mAll && { margin: mAll },
    !!mt && { marginTop: mt },
    !!mb && { marginBottom: mb },
    !!ml && { marginLeft: ml },
    !!mr && { marginRight: mr },
    !!mh && { marginHorizontal: mh },
    !!mv && { marginVertical: mv },
    !!width && { width },
    !!minWidth && { minWidth },
    !!maxWidth && { maxWidth },
    !!height && { height },
    !!minHeight && { minHeight },
    !!pAll && { padding: pAll },
    !!ph && { paddingHorizontal: ph },
    !!pv && { paddingVertical: pv },
    !!pl && { paddingLeft: pl },
    !!pt && { paddingTop: pt },
    !!pr && { paddingRight: pr },
    !!pb && { paddingBottom: pb },
    !!justifyContent && { justifyContent },
    !!alignItems && { alignItems },
    !!backgroundColor && { backgroundColor },
    !!aspectRatio && { aspectRatio },
    !!overflow && { overflow },
    !!borderWidth && { borderWidth },
    !!borderColor && { borderColor },
    !!borderRadius && { borderRadius },
    !!borderBottomLeftRadius && { borderBottomLeftRadius },
    !!borderBottomRightRadius && { borderBottomRightRadius },
    !!borderTopLeftRadius && { borderTopLeftRadius },
    !!borderTopRightRadius && { borderTopRightRadius },
    (!!zIndex || zIndex === 0) && { zIndex },
    !!absolute && { position: 'absolute' },
    (!!top || top === 0) && { top },
    (!!bottom || bottom === 0) && { bottom },
    (!!left || left === 0) && { left },
    (!!right || right === 0) && { right },
    !!disabled && { opacity: 0.5 },
    !!relative && { position: 'relative' },
    !!opacity && { opacity },
    !!fadeIn && { animation: 'fade-in 2s' },
    style,
  ]);
  const selectedStyle = merge({}, ...styleTemplate);

  if (onClick) {
    return (
      <div
        className={className}
        style={merge({ cursor: 'pointer' }, ...styleTemplate)}
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...restProps}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={className}
      style={selectedStyle}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
    >
      {children}
    </div>
  );
};

const styles = {
  // overlay: {
  //     position: 'absolute',
  //     left: 0,
  //     right: 0,
  //     top: 0,
  //     bottom: 0,
  // },
  center: { justifyContent: 'center', alignItems: 'center' },
  hCenter: { justifyContent: 'center' },
  vCenter: { alignItems: 'center' },
  selfCenter: { alignSelf: 'center' },
  spaceBetween: { justifyContent: 'space-between' },
  row: { flexDirection: 'row', display: 'flex' },
};

export default Box;
