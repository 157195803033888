/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { useMemo } from 'react';

export interface ManagedUsersInfo {
  phoneNumber: string | null;
  displayName: string | null;
  uid: string;
}

interface UserState {
  users: ManagedUsersInfo[] | null;
  role: number;
  admin?: ManagedUsersInfo | null;
  loading: boolean;
  updateUser: (data: ManagedUsersInfo) => void;
}

export const ManagedUserStateManager = makeVar<UserState>({
  users: null,
  loading: true,
  role: 0,
  admin: null,
  updateUser: () => {},
});

export const useManagedUsers = (id?: string) => {
  const usersState = useReactiveVar(ManagedUserStateManager);

  const user = useMemo(
    () => usersState.users?.find((_user: ManagedUsersInfo) => _user.uid === id),
    [id, usersState.users],
  );

  return { user: user as ManagedUsersInfo, ...usersState };
};
