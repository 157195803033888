import * as React from 'react';
import { useDarkModeState } from '../stateManagement/DarkModeStateManagement';
import Box from './Box';
import { Switch } from '@mui/material';

const DarkModeSwitch = () => {
  const [darkMode, update] = useDarkModeState();
  return (
    <Box>
      <Switch checked={darkMode} onChange={update} />
    </Box>
  );
};

export default DarkModeSwitch;
