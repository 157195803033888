/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-misused-promises */
import {
  Button,
  CircularProgress,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import Box from '../Box';
import SPACES from '../../utils/CONSTS/SPACES';
import { useFirebaseUpload } from '../../utils/hooks/useFirebaseUpload';
import { LoadingButton } from '@mui/lab';

interface AddImageModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (urls: string[]) => void;
  handelUpdateReport: () => Promise<void>;
}

const AddImageModal = ({
  isOpen,
  onClose,
  onSave,
  handelUpdateReport,
}: AddImageModalProps) => {
  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = React.useState<any[]>([]);

  const { urls, error, isLoading, uploadImage } = useFirebaseUpload(onSave);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      if (urls.length) {
        await handelUpdateReport();
        onClose();
      }
    })();
  }, [urls]);

  const onHandleUpload = async () => {
    setLoading(true);
    await uploadImage(selectedFiles);
    setSelectedFiles([]);
    setImagePreviews([]);
    setLoading(false);
  };

  const theme = useTheme();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      setImageLoading((prev: any) => {
        const newState: any = {};
        filesArray.forEach((_, index) => {
          newState[index] = true;
        });
        return { ...prev, ...newState };
      });
      setSelectedFiles(filesArray);
      generatePreviewAndDimensions(filesArray);
    }
  };

  const generatePreviewAndDimensions = (files: File[]) => {
    const previews: any[] = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          previews.push({
            url: e.target.result as string,
            width: img.width,
            height: img.height,
          });
          // Only update state once all images are loaded
          if (previews.length === files.length) {
            setImagePreviews(previews);
          }
        };
        img.src = e.target.result as string;
      };
      reader.readAsDataURL(file);
    });
  };

  const [imageLoading, setImageLoading] = React.useState<any>(null);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="modal"
        height="580px"
        backgroundColor={theme.palette.background.paper}
        width="720px"
        pAll={SPACES?.SPACE_XXL}
        relative
        borderRadius={SPACES?.SPACE_S}
      >
        <Box flex column height={'100%'}>
          <Typography variant="h5" color="primary" fontWeight="bold">
            Add Image
          </Typography>
          <input type="file" multiple onChange={handleFileChange} />
          {isLoading && <p>Uploading...</p>}
          {error && <p>Error: {error.message}</p>}
          <Box row flex>
            {imagePreviews.map((url, index: number) => (
              <Box key={url.url} mr={SPACES.SPACE_XS}>
                {imageLoading?.[index] && <CircularProgress />}
                <img
                  onLoad={() => {
                    setImageLoading((prev: any) => ({
                      ...prev,
                      [index]: false,
                    }));
                  }}
                  src={url.url}
                  alt="Uploaded"
                  style={{
                    width: 100,
                    aspectRatio: url?.width / url?.height,
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>
        <LoadingButton
          title="Upload"
          onClick={onHandleUpload}
          loading={loading}
          variant="contained"
          color="primary"
          style={{ position: 'absolute', bottom: 20, right: 20 }}
        >
          Upload
        </LoadingButton>
      </Box>
    </Modal>
  );
};

export default AddImageModal;
