/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { AE_TOKEN, EBAY_TOKEN } from '../hooks/useSyncToken';
import { getAuth } from 'firebase/auth';
import { collection, doc, getFirestore, setDoc } from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

export interface IUserTokenState {
  AE_TOKEN: any;
  EBAY_TOKEN: any;
}

export const UserTokenState = makeVar<IUserTokenState | any>({
  AE_TOKEN: null,
  EBAY_TOKEN: null,
});

export const useTokens = () => {
  const userTokenState = useReactiveVar<IUserTokenState | any>(UserTokenState);
  const setUserToken = async (token: any, tokenType: string) => {
    try {
      if (!auth.currentUser?.uid) return;
      const userTokenRef = doc(
        collection(db, `users/${auth.currentUser?.uid}/tokens`),
      );

      await setDoc(userTokenRef, { [tokenType]: token }, { merge: true });
    } catch (error) {
      console.error('Error setting token', error);
    }
  };
  return [userTokenState, setUserToken];
};
