import * as React from 'react';
import { Outlet, Link } from 'react-router-dom';
import Box from '../components/Box';
import COLORS from '../utils/CONSTS/COLORS';
import Logo from '../components/Logo';

const AuthLayout = () => {
  return (
    <Box>
      <Box backgroundColor={COLORS.PRIMARY}>
        <nav>
          <Box flex row spaceBetween>
            <Logo size={50} withFont />
            <ul className="auth_navbar">
              <li className="auth_navbar_item">
                <Link to="/">Home</Link>
              </li>
              <li className="auth_navbar_item">
                <Link to="/blogs">Blogs</Link>
              </li>
              <li className="auth_navbar_item">
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <ul className="auth_navbar">
              <li className="auth_navbar_item">
                <Link to="/login">Login</Link>
              </li>
              <li className="auth_navbar_item">
                <Link to="/register">Register</Link>
              </li>
            </ul>
          </Box>
        </nav>
      </Box>

      <Outlet />
    </Box>
  );
};

export default AuthLayout;
