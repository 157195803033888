import { useEffect, useState } from 'react';
import { firebaseCall } from '../CONSTS/ENDPOINTS';
import { useUser } from '../../stateManagement/userStateManager';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import {
  ManagedUserStateManager,
  type ManagedUsersInfo,
} from '../../stateManagement/ManagedUserStateManager';

interface useUsersProfileProps {
  all?: boolean;
}
const functions = getFunctions();

const SYNCING = 0;

const useSyncManagedUsersProfile = () => {
  const { user } = useUser();
};

export default useSyncManagedUsersProfile;
