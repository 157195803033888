import { createTheme } from '@mui/material/styles';
import { grey, red, common, deepOrange } from '@mui/material/colors';
import { useReactiveVar } from '@apollo/client';
import { useDarkModeState } from './stateManagement/DarkModeStateManagement';


declare module '@mui/material/styles' {
  // eslint-disable-next-line prettier/prettier
  interface palette {
    surface?: {
      main: string,
      dark: string,
    }
  }
  interface PaletteOptions {
    surface?: {
      main?: string,
      dark?: string,
    }
  }
}


const useThemeProvider = () => {
  const [darkModeState] = useDarkModeState();
  // A custom theme for this app
  const theme = createTheme({
    palette: {
      mode: darkModeState ? 'dark' : 'light',
      ...(!darkModeState
        ? {
            primary: {
              main: '#0842a0',
              dark: '#13144e',
              light: '#d8d8f1',
            },
            secondary: {
              main: grey[600],
             
            },
            background: {
              default: common.white,
              paper: grey[100],
            },
            surface: {
              main: "#f3f7fd",
              dark: '#0842a0',
            },
            error: {
              main: red.A400,
            },
          }
        : {
            primary: {
              main: '#4488f9',
              dark: '#13144e',
              light: '#d8d8f1',
              contrastText: common.white,
            },
            secondary: {
              main: '#91abb4',
            },
            surface: {
              main: '#192a38',
              dark: '#0d2136',
            },
            background: {
              default: '#031525',
              paper: '#031525',
            },
            error: {
              main: red.A400,
            },
          }),
    },
  });

  return theme;
};

export default useThemeProvider;
