import * as React from 'react';
import Box from '../../components/Box';
import { useNavigate, useParams } from 'react-router-dom';
import useReport from '../../utils/hooks/useReport';
import moment from 'moment';
import {
  Button,
  Container,
  LinearProgress,
  Paper,
  Typography,
} from '@mui/material';
import { Dashboard } from '@mui/icons-material';
import DashboardLayout from '../../layouts/DashboardLayout';

const ReportLanding = () => {
  const { id } = useParams();
  const { report } = useReport({ id });
  const navigate = useNavigate();
  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <DashboardLayout />
      <Container fixed>
        <Box minWidth={1000}>
          <p>{report?.name}</p>
          <p>{report?.type}</p>
          <p>{report?.author}</p>
          {report?.completionState?.percentageCompletion && (
            <Box>
              <Typography variant="h6">Progress</Typography>
              <Box row flex vCenter>
                <LinearProgress
                  variant="determinate"
                  value={report?.completionState?.percentageCompletion}
                  style={{ width: '20%' }}
                />
                <Typography variant="h6" className="pl-3">
                  {report?.completionState?.percentageCompletion} %
                </Typography>
              </Box>
            </Box>
          )}
          {report?.date && <p>{moment(report.date).format('dddd DD.MMM.Y')}</p>}
          <Button
            onClick={() => {
              navigate(`/report/${id}/edit`);
            }}
          >
            Edit
          </Button>
        </Box>
      </Container>
    </Paper>
  );
};

export default ReportLanding;
