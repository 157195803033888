import { StyleSheet } from '@react-pdf/renderer';
const PRIMARY_COLOR = '#4d2f69';

export const styles = StyleSheet.create({
  headerImage: {
    width: 100,
    height: 100,
  },
  container: {
    paddingVertical: 30,
    paddingHorizontal: 20,
  },
  pageContainer: {
    paddingTop: 10,
    paddingHorizontal: 75,
  },
  textBody: {
    fontFamily: 'manrope',
    fontSize: 10,
    fontWeight: 500,
  },
  textBold: {
    fontWeight: 800,
  },
  textBodyX: {
    fontFamily: 'manrope',
    fontSize: 10,
    fontWeight: 500,
  },
  textWhite: {
    color: '#fff',
  },
  footerText: {
    ontFamily: 'manrope',
    fontWeight: 800,
    fontSize: 10,
  },
  subTitle: {
    fontFamily: 'manrope',
    fontSize: 16,
  },
  heading1: {
    fontFamily: 'manrope',
    fontSize: 40,
    fontWeight: 800,
  },
  heading2: {
    fontFamily: 'manrope',
    fontSize: 30,
    fontWeight: 800,
    color: PRIMARY_COLOR,
  },
  heading3: {
    fontFamily: 'manrope',
    fontSize: 20,
    fontWeight: 800,
    color: PRIMARY_COLOR,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  mv1: {
    marginVertical: 6,
  },
  mv2: {
    marginVertical: 12,
  },
  mv3: {
    marginVertical: 20,
  },
  mt1: {
    marginTop: 6,
  },
  mt2: {
    marginTop: 12,
  },
  mt3: {
    marginTop: 20,
  },
  mt4: {
    marginTop: 30,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  borderField: {
    borderColor: 'grey',
    minHeight: 20,
    borderWidth: 1,
    padding: 5,
    borderRadius: 3,
  },
});
