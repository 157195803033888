import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSyncManagedUsersProfile from '../../utils/hooks/useSyncManagedUsersProfile';
import { useUser } from '../../stateManagement/userStateManager';

const AEAuthCode = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const code = searchParams.get('code');
  const { user, updateUser } = useUser();
  console.log('searchParams', code);

  useEffect(() => {
    if (code) {
      updateUser({ aEAuthCode: code });
      navigate('/');
    }
  }, [code]);

  return (
    <div>
      <p>aEAuthCode</p>
    </div>
  );
};

export default AEAuthCode;
