/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Firestore,
  addDoc,
  collection,
  doc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from 'firebase/firestore';

import { useUser } from '../../stateManagement/userStateManager';
import { type CreateReportType } from '../TYPES';
import { useEffect, useState } from 'react';
import { round } from 'lodash';

interface UseUpdateReportProps {
  reportData: any;
  reportId: string | undefined;
  setSnackBarState: React.Dispatch<
    React.SetStateAction<{
      open: boolean,
      message: string,
      // eslint-disable-next-line prettier/prettier
    }>
  >;
}

const useUpdateReport = ({
  reportData,
  reportId,
  setSnackBarState,
}: UseUpdateReportProps) => {
  const db = getFirestore();
  const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);

  const [completionState, setCompletionState] = useState({
    numberOfField: 0,
    numberOfFieldFilled: 0,
    percentageCompletion: 0,
  });

  const handelUpdateReport = async () => {
    if (!user) return;
    setLoading(true);
    const reportBodyRef = doc(
      collection(
        db,
        `organisations/${user?.organisation}/reports/${reportId}/report`,
      ),
    );
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    await setDoc(
      reportBodyRef,
      {
        dateSaved: serverTimestamp(),
        reportData,
      },
      { merge: true },
    );

    const reportRef = doc(db, `users/${user?.uid}/reports/${reportId}`);
    await setDoc(
      reportRef,
      {
        completionState,
      },
      { merge: true },
    );

    setLoading(false);
    setSnackBarState({
      open: true,
      message: 'Report Updated Successfully',
    });
  };

  useEffect(() => {
    let numberOfField = 0;
    let numberOfFieldFilled = 0;

    Object.entries(reportData).forEach(([key, value]) => {
      if (!value) return;
      numberOfField += Object.entries(value).length;
      numberOfFieldFilled += Object.entries(value).filter(
        ([key2, value2]) => value2.text !== '' && value2.text.length > 3,
      ).length;
    });

    setCompletionState({
      numberOfField,
      numberOfFieldFilled,
      percentageCompletion: round((numberOfFieldFilled / numberOfField) * 100),
    });
  }, [reportData]);

  return { handelUpdateReport, loading, completionState };
};

export default useUpdateReport;
