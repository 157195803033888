import * as React from 'react';
import useSyncManagedUsersProfile from '../utils/hooks/useSyncManagedUsersProfile';
import { Outlet } from 'react-router-dom';
import useSyncToken from '../hooks/useSyncToken';

const LoggedInAppLayout = () => {
  useSyncManagedUsersProfile();
  useSyncToken();
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default LoggedInAppLayout;
