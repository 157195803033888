import React from 'react';

import { type ModalStateType } from '../../stateManagement/ModalStateManager';

interface ReadyResponseModalProps {
  visible: boolean;
  onClose: () => void;
  modalState: ModalStateType;
}

const ReadyResponseModal: React.FC<ReadyResponseModalProps> = ({
  visible,
  onClose,
  modalState,
}) => {
  return <div />;
};

export default ReadyResponseModal;
