import { Box, LinearProgress, Typography } from '@mui/material';
import React from 'react';

interface LinearProgressProps {
  value: number;
}

export default function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number },
) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 300,
      }}
    >
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
