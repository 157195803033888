/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import level2 from '../../RICSFORMAT/level2.json';
import ReportSection from '../../components/ReportSection';

import ReadyResponseModal from '../../components/modals/ReadyResponseModal';
import { useModalStateManager } from '../../stateManagement/ModalStateManager';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  Paper,
  Snackbar,
  Switch,
} from '@mui/material';
import useUpdateReport from '../../utils/hooks/useUpdateReport';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { ReportStateManager } from '../../stateManagement/ReportsStateManagement';
import useSyncUserReports from '../../utils/hooks/useUserReports';
import useReport from '../../utils/hooks/useReport';

import { round } from 'lodash';
import Loading from '../../components/Loading';
import {
  styled,
  useTheme,
  type Theme,
  type CSSObject,
} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {
  type AppBarProps as MuiAppBarProps,
} from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import SPACES from '../../utils/CONSTS/SPACES';
import AddImageModal from '../../components/modals/AddImageModal';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import moment from 'moment';

const ReportEditor = () => {
  const FORM_STRUCTURE = level2;

  const [modalState, updateModalState] = useModalStateManager();

  const { id } = useParams();

  const navigate = useNavigate();

  const createInitialState = () =>
    Object.fromEntries(
      FORM_STRUCTURE.sections.map(({ title, sectionAlphabet, sections }) => [
        sectionAlphabet,
        Object.fromEntries(
          sections.map((value2) => [
            value2?.title,
            {
              text: '',
              images: [],
              notes: '',
              notesImages: [],
              state: 0,
            },
          ]),
        ),
      ]),
    );

  const [reportState, setReportState] =
    React.useState<any>(createInitialState());

  const createInitialSectionCollapseState = () =>
    Object.fromEntries(
      FORM_STRUCTURE.sections.map(({ sectionAlphabet }) => [
        sectionAlphabet,
        true,
      ]),
    );

  const [allExpanded, setAllExpanded] = React.useState<boolean>(true);

  React.useEffect(() => {
    setCollapsedSections(
      Object.fromEntries(
        Object.keys(collapsedSections).map((key) => [key, allExpanded]),
      ),
    );
  }, [allExpanded]);

  const [collapsedSections, setCollapsedSections] = React.useState<any>(
    createInitialSectionCollapseState(),
  );
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    message: '',
  });

  const {
    handelUpdateReport,
    loading: savingLoading,
    completionState,
  } = useUpdateReport({
    reportData: reportState,
    reportId: id,
    setSnackBarState,
  });

  const handleSnackBarClose = () => {
    setSnackBarState({ ...snackBarState, open: false });
  };

  const {
    reportBody,
    loading: reportLoading,
    revertToPreviousReport,
  } = useReport({ id });

  console.log('reportBodyreportBody', reportBody?.dateSaved);

  React.useEffect(() => {
    if (!reportBody?.reportData) return;
    setReportState(reportBody.reportData);
  }, [reportBody]);

  const [isSaveActive, setIsSaveActive] = React.useState(false);

  React.useEffect(() => {
    if (reportLoading) {
      setIsSaveActive(false);
      return;
    }

    setIsSaveActive(reportState !== reportBody?.reportData);
  }, [reportState, reportBody?.reportData, reportLoading]);

  if (reportLoading) return <Loading />;

  return (
    <Box sx={{ display: 'flex', pt: 12 }}>
      <AppBarAndDrawer
        setAllExpanded={setAllExpanded}
        allExpanded={allExpanded}
        revertToPreviousReport={revertToPreviousReport}
        reportBody={reportBody}
        navigate={navigate}
        completionState={completionState}
        id={id}
      />
      <Box sx={{ flexGrow: 1, pl: 3, pr: 3 }}>
        {/* <a href="#fullAddress"> SCROLL</a> */}
        {FORM_STRUCTURE.sections.map((section) => (
          <ReportSection
            key={section?.sectionAlphabet}
            title={section?.title ?? ''}
            section={section}
            sectionState={reportState?.[section?.sectionAlphabet]}
            savedSectionState={
              reportBody?.reportData?.[section?.sectionAlphabet]
            }
            setSectionState={(value: any) => {
              setReportState({
                ...reportState,
                [section?.sectionAlphabet]: value,
              });
            }}
            collapseStateSection={collapsedSections?.[section?.sectionAlphabet]}
            setCollapsedSections={(value: any) => {
              setCollapsedSections((prevState: any) => ({
                ...prevState,
                [section?.sectionAlphabet]:
                  !prevState?.[section?.sectionAlphabet],
              }));
            }}
            handelUpdateReport={() => {
              (async () => {
                await handelUpdateReport();
              })();
            }}
            savingLoading={savingLoading}
          />
        ))}

        <ReadyResponseModal
          visible={modalState.open}
          onClose={() => {
            updateModalState({ open: false });
          }}
          modalState={modalState}
          // title={ModalState.title}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBarState?.open}
          onClose={handleSnackBarClose}
          key={'top' + 'center'}
          autoHideDuration={4000}
        >
          <Alert
            onClose={handleSnackBarClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            <Typography color={'white'}>{snackBarState?.message}</Typography>
          </Alert>
        </Snackbar>
      </Box>

      <AddImageModal
        isOpen={modalState.open && modalState.type === 'addImage'}
        onSave={modalState?.onAction}
        handelUpdateReport={handelUpdateReport}
        onClose={() => {
          updateModalState({
            open: false,
            type: '',
            title: '',
            onAction: () => {},
          });
        }}
      />
    </Box>
  );
};

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const AppBarAndDrawer = ({
  setAllExpanded,
  allExpanded,
  revertToPreviousReport,
  reportBody,
  navigate,
  completionState,
  id,
}: any) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'surface.dark',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LinearProgress
                color="inherit"
                variant="determinate"
                value={completionState?.percentageCompletion}
                sx={{ width: 120, height: 20, ml: 2, mr: 2 }}
              />
              <Typography variant="h6" noWrap component="div">
                {completionState?.percentageCompletion}%
              </Typography>
              <DarkModeSwitch />
            </Box>
            <Box
              onClick={() => {
                navigate(`/report/${id}/preview`);
              }}
            >
              Preview
            </Box>
            <div
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={async () => {
                await revertToPreviousReport();
              }}
            >
              revert{' '}
              {moment(reportBody?.dateSaved?.toDate()).format(
                'DD/MM/YYYY hh:mm:ss',
              )}
            </div>
            <Box
              sx={{
                display: 'flex',
                color: 'primary',
                ':hover': { cursor: 'pointer' },
              }}
              onClick={() => {
                navigate(`/report/${id}`);
              }}
            >
              <ExitToAppIcon sx={{ pr: 0.5 }} color="primary" />
              <Typography noWrap component="div">
                Exit
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding key="Progress">
            <ListItemButton>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Progress" />
              <CircularProgress
                color="inherit"
                thickness={10}
                variant="determinate"
                size={20}
                value={completionState?.percentageCompletion}
                sx={{ width: 20, height: 20, ml: 2 }}
              />
            </ListItemButton>
          </ListItem>
          {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Box>
          <Switch
            color="primary"
            size="medium"
            sx={{ ml: 'auto' }}
            checked={allExpanded}
            onChange={(value) => {
              setAllExpanded(value.target.checked);
            }}
          />
        </Box>
      </Drawer>
      <DrawerHeader />
    </>
  );
};

export default ReportEditor;
