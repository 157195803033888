import * as React from 'react';
import Box from './Box';
import { Alert, TextField, Typography } from '@mui/material';
import SPACES from '../utils/CONSTS/SPACES';
import LoadingButton from '@mui/lab/LoadingButton';
import { toLower } from 'lodash';
import { SmsFailed } from '@mui/icons-material';

interface AuthComponentProps {
  type: string;
  onClick: (email: string, password: string, name?: string) => void;
  loading?: boolean;
}

const AuthComponent = ({ type, onClick, loading }: AuthComponentProps) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [name, setName] = React.useState('');

  const componentWidth = { width: '100%' };
  const isRegister: boolean = toLower(type) === 'register';

  const [isNotValid, setIsNotValid] = React.useState(false);

  const validate = React.useMemo(() => {
    if (isRegister) {
      if (name === '' || name?.length < 3) {
        return false;
      }
    }
    if (email === '') {
      return false;
    }
    if (password === '') {
      return false;
    }
    return true;
  }, [name, email, password]);

  return (
    <Box style={{ width: 300 }}>
      <Typography variant="h6" textAlign={'center'}>
        {type}
      </Typography>
      <Box center>
        {isRegister && (
          <Box mt={SPACES.SPACE_S}>
            <TextField
              id="outlined-basic"
              label="Name"
              variant="outlined"
              onChange={(e) => {
                setName(e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
        )}
        <Box mt={SPACES.SPACE_S}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            style={componentWidth}
          />
        </Box>
        <Box mt={SPACES.SPACE_S}>
          <TextField
            id="outlined-basic"
            label="Password"
            type="password"
            variant="outlined"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            style={componentWidth}
          />
        </Box>
      </Box>
      <Box mt={SPACES.SPACE_S} flex>
        <LoadingButton
          variant="contained"
          color="primary"
          size="large"
          loading={loading}
          disabled={!validate}
          onClick={() => {
            if (validate) {
              onClick(email, password, name);
            }
          }}
          style={{ width: '100%' }}
        >
          {type}
        </LoadingButton>
      </Box>
      {!validate && (
        <Box mt={SPACES.SPACE_S}>
          <Alert icon={<SmsFailed fontSize="inherit" />} severity="error">
            Please fill out all the fields
          </Alert>
        </Box>
      )}
    </Box>
  );
};

export default AuthComponent;
