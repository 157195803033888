const SPACE_XXS = 4;
const SPACE_XS = 8;
const SPACE_S = 12;
const SPACE_M = 16;
const SPACE_L = 24;
const SPACE_XL = 32;
const SPACE_XXL = 48;
const SPACE_XXXL = 64;
const SPACE_XXXXL = 96;
const SPACE_XXXXXL = 128;

export default {
  SPACE_XXS,
  SPACE_XS,
  SPACE_S,
  SPACE_M,
  SPACE_L,
  SPACE_XL,
  SPACE_XXL,
  SPACE_XXXL,
  SPACE_XXXXL,
  SPACE_XXXXXL,
};
