import { useState, useEffect } from 'react';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { useUser } from '../../stateManagement/userStateManager';
import moment from 'moment';

// Define the hook's return type
interface UseFirebaseUploadResult {
  urls: string[];
  error: Error | null;
  isLoading: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertImageToPNG(imageSrc: any, callback: any) {
  // Create an Image object
  const img = new Image();
  // CORS permission
  img.crossOrigin = 'anonymous';
  img.onload = function () {
    // Create a canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // Set canvas dimensions to match the image
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the image onto the canvas
    ctx?.drawImage(img, 0, 0);
    // Get the image data URL in PNG format
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  };
  // Handle possible errors
  img.onerror = function () {
    callback(new Error('Could not load image'));
  };
  // Set the source of the image to trigger the load
  img.src = imageSrc;
}

export const useFirebaseUpload = (
  onSave: (files: string[]) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handelUpdateReport?: any,
) => {
  const [urls, setUrls] = useState<string[]>([]);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();

  const uploadImage = async (files: File[]) => {
    if (!files.length) return;

    setIsLoading(true);
    const storage = getStorage();
    const uploadPromises = files.map(async (file) => {
      const storageRef = ref(
        storage,
        `userImages/${user?.uid}/${moment().unix()}-${file.name}`,
      );
      console.log('storageRef', storageRef, moment().unix());

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const base64data = reader.result;
        convertImageToPNG(base64data, (dataURL: string) => {
          console.log('dataURL', dataURL);
        });
      };

      const uploadTask = uploadBytesResumable(storageRef, file);
      return await new Promise<string>((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            // Handle upload progress or any other state change if necessary
          },
          (_error) => {
            reject(_error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          },
        );
      });
    });

    const _urls = await Promise.all(uploadPromises);

    onSave(_urls);
    setUrls(_urls);

    setIsLoading(false);
  };

  return { uploadImage, urls, error, isLoading };
};
