/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';

const useAmazon = (resetList: () => void, region: string) => {
  const [bestSellers, setBestSellers] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  console.log('bestSellersbestSellers', bestSellers);

  const getBestSellers = async (category: string, page: number) => {
    setLoading(true);
    resetList();
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log('HEEREEE', JSON.parse(this?.response));
          setBestSellers(JSON.parse(this.response)?.data?.best_sellers);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/best-sellers?category=${category?.toLowerCase()}&type=BEST_SELLERS&page=${page}&country=${region}`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const searchProduct = async (query: string, page: number) => {
    resetList();
    setLoading(true);
    setBestSellers([]);
    try {
      const data = null;

      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;

      xhr.addEventListener('readystatechange', function () {
        if (this.readyState === this.DONE) {
          console.log(this.responseText);
          setBestSellers(JSON.parse(this.responseText)?.data?.products);
          setLoading(false);
        }
      });

      xhr.open(
        'GET',
        `https://real-time-amazon-data.p.rapidapi.com/search?query=${query}&page=${page}&country=${region}&sort_by=RELEVANCE&category_id=2619534011&product_condition=NEW&is_prime=false`,
      );
      xhr.setRequestHeader(
        'x-rapidapi-key',
        '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
      );
      xhr.setRequestHeader(
        'x-rapidapi-host',
        'real-time-amazon-data.p.rapidapi.com',
      );

      xhr.send(data);
    } catch (error) {
      console.error(error);
    }
  };

  return { bestSellers, loading, getBestSellers, searchProduct };
};

export default useAmazon;
