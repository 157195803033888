import * as React from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import Box from '../components/Box';
import COLORS from '../utils/CONSTS/COLORS';
import { Button, Container, Typography } from '@mui/material';
import useAuth from '../utils/hooks/useAuth';
import LogoutIcon from '@mui/icons-material/Logout';
import SPACES from '../utils/CONSTS/SPACES';
import Logo from '../components/Logo';
import useSyncManagedUsersProfile from '../utils/hooks/useSyncManagedUsersProfile';
import DarkModeSwitch from '../components/DarkModeSwitch';

const SuperAdminDashboardLayout = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  return (
    <Box>
      <Box backgroundColor={COLORS.PRIMARY}>
        <Container>
          <Box minWidth={1000}>
            <nav>
              <Box flex row spaceBetween>
                <ul
                  className="auth_navbar"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <Logo size={50} withFont />
                </ul>
                <ul className="auth_navbar">
                  <li className="auth_navbar_item">
                    <DarkModeSwitch />
                  </li>
                </ul>
                <ul className="auth_navbar">
                  <li className="auth_navbar_item">
                    <Button
                      onClick={logout}
                      variant="text"
                      color="secondary"
                      style={{ color: 'white' }}
                    >
                      Logout
                      <Box pl={SPACES.SPACE_XXS}>
                        <LogoutIcon />
                      </Box>
                    </Button>
                  </li>
                </ul>
              </Box>
            </nav>
          </Box>
        </Container>
      </Box>
      <Outlet />
    </Box>
  );
};

export default SuperAdminDashboardLayout;
