import React from 'react';
import Box from '../../components/Box';
import { Button, Container, Paper, Typography } from '@mui/material';
import SPACES from '../../utils/CONSTS/SPACES';
import { useNavigate, useParams } from 'react-router-dom';
import { useManagedUsers } from '../../stateManagement/ManagedUserStateManager';
import Loading from '../../components/Loading';
import Encryptor from '../../utils/helpers/Encryptor';

const UserDetail = () => {
  const { id } = useParams();
  const decrypt = Encryptor.decrypt(id);
  // const decode: string = registry.decode(Number(id)) ?? '';

  console.log('decodedecode', decrypt);

  const { user, loading } = useManagedUsers(decrypt);
  const navigate = useNavigate();

  if (loading) {
    return <Loading />;
  }

  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Container fixed>
        <Box minWidth={1000} pt={SPACES.SPACE_L}>
          <Button
            onClick={() => {
              navigate('/users');
            }}
          >
            Back
          </Button>
          <Typography variant="h6">Profile</Typography>

          <Typography>{user?.displayName}</Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default UserDetail;
