import * as React from 'react';
import Box from './Box';
import { Typography } from '@mui/material';
import COLORS from '../utils/CONSTS/COLORS';

interface LogoProps {
  size?: number;
  withFont?: boolean;
}

const Logo = ({ size = 100, withFont }: LogoProps) => {
  return (
    <Box row flex center width={withFont ? size * 4 : size} height={size}>
      <img
        src="https://firebasestorage.googleapis.com/v0/b/myreportio-f874d.appspot.com/o/logo.png?alt=media&token=b4e435b6-9f16-4da1-8656-a0619e69dead"
        alt="MyReportAI Logo"
        width={size}
        height={size}
      />
      {withFont && (
        <Typography color={COLORS.WHITE} fontWeight={'900'}>
          Drop Compare
        </Typography>
      )}
    </Box>
  );
};

export default Logo;
