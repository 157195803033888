import { getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  type DocumentData,
  getDocs,
  getFirestore,
  setDoc,
} from 'firebase/firestore';
import { useEffect } from 'react';
import { UserTokenState, useTokens } from '../stateManagement/UserTokenState';
import { useUser } from '../stateManagement/userStateManager';

const auth = getAuth();
const db = getFirestore();

export const AE_TOKEN = 'AE_TOKEN';
export const EBAY_TOKEN = 'EBAY_TOKEN';

const tokens = {
  [AE_TOKEN]: {},
  [EBAY_TOKEN]: {},
};

const useSyncToken = () => {
  const [, setToken] = useTokens();
  const { user } = useUser();
  const code = user?.code;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkTokens = (allTokens: any) => {
    const aeToken = allTokens[AE_TOKEN];
    const ebayToken = allTokens[EBAY_TOKEN];

    console.log('all tokens', aeToken, ebayToken);
  };

  const getTokens = async () => {
    try {
      if (!auth.currentUser?.uid) return;
      const userTokenRef = collection(
        db,
        'users',
        auth.currentUser?.uid,
        'tokens',
      );
      const tokensSnapShot = await getDocs(userTokenRef);
      const allTokens: DocumentData =
        Object.fromEntries(
          tokensSnapShot.docs.map((document) => [document.id, document.data()]),
        ) || null;
      UserTokenState(allTokens);
      checkTokens(allTokens);
    } catch (error) {
      console.error('Error getting tokens', error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    getTokens();
  }, []);
};

export default useSyncToken;
