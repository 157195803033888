/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { type User, getAuth } from 'firebase/auth';
import {
  type DocumentData,
  doc,
  getDoc,
  getFirestore,
  setDoc,
} from 'firebase/firestore';

import useIsLoggedIn from './useIsLoggedin';
import { UserStateManagerState } from '../../stateManagement/userStateManager';

const useSyncUser = () => {
  const auth = getAuth();
  const database = getFirestore();
  const isLoggedIn = useIsLoggedIn();

  const getUserAndPermission = async (): Promise<void> => {
    UserStateManagerState({ ...UserStateManagerState(), loading: true });
    if (auth?.currentUser?.uid) {
      const userRef = doc(database, 'users', auth.currentUser?.uid);
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData: DocumentData = userSnap.data();
        const merge = { ...auth?.currentUser, ...userData };
        const perm = await auth.currentUser?.getIdTokenResult();

        UserStateManagerState({
          ...UserStateManagerState(),
          permissionStatus: {
            superAdmin: !!perm?.claims?.superAdmin,
            admin: !!perm?.claims?.admin,
          },
          user: merge,
          loading: false,
        });
      }
    }
  };

  const updateUser = async (data: any) => {
    if (!auth.currentUser?.uid) return;
    try {
      const userRef = doc(database, 'users', auth.currentUser?.uid);
      await setDoc(userRef, data, { merge: true });
    } catch (error) {
      console.error('Error signing in with email and password', error);
    }
  };

  React.useEffect(() => {
    getUserAndPermission();
    UserStateManagerState({
      ...UserStateManagerState(),
      updateUser,
    });
  }, [isLoggedIn]);
};

export default useSyncUser;
