import { capitalize } from 'lodash';

export const ACCOUNT_TYPE = {
  SUPERADMIN: 'superAdmin',
  ORGANISATION: 'organisation',
  ADMIN: 'admin',
  EMPLOYEE: 'employee',
};

export const ACCOUNT_TYPE_OPTIONS = [
  {
    value: ACCOUNT_TYPE?.ORGANISATION,
    label: capitalize(ACCOUNT_TYPE?.ORGANISATION),
  },
  { value: ACCOUNT_TYPE?.ADMIN, label: capitalize(ACCOUNT_TYPE?.ADMIN) },
  {
    value: ACCOUNT_TYPE?.EMPLOYEE,
    label: capitalize(ACCOUNT_TYPE?.EMPLOYEE),
  },
];
