import * as React from 'react';
import Box from '../../components/Box';
import AuthComponent from '../../components/AuthCompoenet';
import useAuth from '../../utils/hooks/useAuth';

const LoginPage = () => {
  const { login, loading } = useAuth();
  return (
    <Box center flex column minHeight={'90vh'}>
      <AuthComponent
        type="Login"
        loading={loading}
        onClick={(email, password) => {
          console.log('email', email);
          console.log('password', password);
          login({ email, password });
        }}
      />
    </Box>
  );
};

export default LoginPage;
