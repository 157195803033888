import * as React from 'react';
import AuthComponent from '../../components/AuthCompoenet';
import Box from '../../components/Box';
import useAuth from '../../utils/hooks/useAuth';

const RegisterPage = () => {
  const { register } = useAuth();
  return (
    <Box center flex column minHeight={'90vh'}>
      <AuthComponent
        type="Register"
        onClick={(email, password, displayName) => {
          console.log('email', email);
          console.log('password', password);
          register({ email, password, displayName });
        }}
      />
    </Box>
  );
};

export default RegisterPage;
