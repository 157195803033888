/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  type DocumentData,
  doc,
  getDoc,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  and,
  setDoc,
  addDoc,
} from 'firebase/firestore';
import { type UserInfo } from 'firebase/auth';
import PageWrapper from '../../components/PageWrapper';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_OPTIONS,
} from '../../utils/CONSTS/SUPERADMINTERMS';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { capitalize } from 'lodash';
import SPACES from '../../utils/CONSTS/SPACES';
import { LoadingButton } from '@mui/lab';
import { Save } from '@mui/icons-material';

const db = getFirestore();
const functions = getFunctions();

const userDetail = {};

const EditUserSA = () => {
  const { id } = useParams();

  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    (async () => {
      console.log('id', id);
      const ref = doc(db, `users/${id}`);

      const snap = await getDoc(ref);
      if (snap.exists()) {
        setUser(snap.data() as UserInfo);
      }
    })();
  }, [id]);

  const updateUser = (key: string, value: string | number | boolean) => {
    setUser((prev: any) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const componentWidth = { width: 400 };

  const [orgItems, setOrgItems] = useState<any[]>([]);

  const findOrganisation = async (value: string) => {
    // find organisation
    if (value.length < 3) {
      setOrgItems([]);
      return;
    }
    const ref = collection(db, 'organisations');
    const q = query(
      ref,
      and(where('name', '>=', value), where('name', '<', value + 'z')),
    );
    const querySnapshot = await getDocs(q);
    const items: object[] = [];
    querySnapshot.forEach((item: any) => {
      console.log(item.id, ' => ', item.data());
      items.push({ id: item?.id, ...(item.data() as object) });
    });
    if (items?.length) {
      console.log('itemsitems', items);

      setOrgItems(items);
    } else {
      setOrgItems([]);
    }
  };

  const [selectedOrg, setSelectedOrg] = useState<any>(null);

  useEffect(() => {
    if (orgItems?.length === 0) return;
    if (!user?.organisationName) {
      setSelectedOrg(null);
      return;
    }
    setSelectedOrg(
      orgItems?.find((item) => item?.name === user?.organisationName),
    );
    updateUser('organisation', selectedOrg?.id as string);
  }, [user?.organisationName, orgItems]);

  console.log('USEEERRR', user);

  const isOrganisationAccount =
    user?.accountType === ACCOUNT_TYPE?.ORGANISATION;
  const isEmployee = user?.accountType === ACCOUNT_TYPE?.EMPLOYEE;
  const setUserPerm = (orgId?: string) => {
    const setUserOrg = httpsCallableFromURL(
      functions,
      firebaseCall('setUserOrganisation'),
    );
    setUserOrg({
      orgId: orgId ?? user?.organisation,
    })
      .then((res) => {
        console.log('setUser', res);
      })
      .catch((err) => {
        console.log('setUser Error', err);
      });
  };

  console.log('ORgID', user?.organisation);

  const onSave = async () => {
    const userRef = doc(db, `users/${id}`);
    // setUserPerm();
    let orgRef;
    if (user?.organisation) {
      orgRef = doc(db, `organisations/${user?.organisation}`);
    } else {
      orgRef = collection(db, 'organisations');
    }
    // if (user?.organisation) {
    //   setUserPerm();
    // }
    if (isOrganisationAccount) {
      console.log('savving', {
        name: user?.organisationName,
        address: user?.organisationAddress,
        ceo: user?.ceo,
        registrationNumber: user?.registrationNumber,
      });

      // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
      const orgSavedResult = await addDoc(collection(db, 'organisations'), {
        name: user?.organisationName,
        address: user?.organisationAddress,
        ceo: user?.ceo,
        registrationNumber: user?.registrationNumber,
      });
      console.log('orgSavedResult?.id', orgSavedResult);

      const orgId: string = (orgSavedResult as unknown as { id: string })?.id;

      await setDoc(userRef, { ...user, organisation: orgId }, { merge: true });
      //  setUserPerm(orgId);
    }
  };

  if (!user) return null;

  return (
    <PageWrapper center>
      <Typography variant="h5">Editing</Typography>
      <Typography>{user?.email}</Typography>
      <Box mt={2}>
        <TextField
          id="outlined-basic"
          label="Full Name"
          variant="outlined"
          value={user?.displayName}
          onChange={(e) => {
            updateUser('displayName', e.target.value);
          }}
          style={componentWidth}
        />
      </Box>
      <Box mt={2}>
        <FormControl style={componentWidth}>
          <InputLabel id="demo-simple-select-label">Account Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={user?.accountType}
            label="Account Type"
            onChange={(e) => {
              updateUser('accountType', e.target.value as string);
            }}
          >
            {ACCOUNT_TYPE_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {user?.accountType === ACCOUNT_TYPE?.ORGANISATION && (
        <>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="Organisation Name"
              variant="outlined"
              value={user?.organisationName}
              onChange={(e) => {
                updateUser('organisationName', e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="Organisation Address"
              variant="outlined"
              value={user?.organisationAddress}
              onChange={(e) => {
                updateUser('organisationAddress', e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="CEO"
              variant="outlined"
              value={user?.ceo}
              onChange={(e) => {
                updateUser('ceo', e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="Registration Number"
              variant="outlined"
              value={user?.registrationNumber}
              onChange={(e) => {
                updateUser('registrationNumber', e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
        </>
      )}
      {user?.accountType === ACCOUNT_TYPE?.EMPLOYEE && (
        <>
          <Box mt={2}>
            <Autocomplete
              options={orgItems?.map((i: any) => i.name) as string[]}
              onChange={(event: any, newValue: string | null) => {
                updateUser('organisationName', newValue as string);
              }}
              inputValue={user?.organisationName}
              onInputChange={(event, newInputValue) => {
                if (!user?.organisationName) findOrganisation(newInputValue);
              }}
              style={componentWidth}
              renderInput={(params) => (
                <TextField {...params} label="Organisation" />
              )}
            />
          </Box>
          <Box>
            <Typography>{selectedOrg?.name}</Typography>
          </Box>
          <Box mt={2}>
            <TextField
              id="outlined-basic"
              label="Surveyor ID"
              variant="outlined"
              value={user?.organisationAddress}
              onChange={(e) => {
                updateUser('organisationAddress', e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
        </>
      )}
      <Box mt={2}>
        <LoadingButton
          variant="contained"
          onClick={() => {
            console.log('user', user);
            onSave();
          }}
          style={componentWidth}
          startIcon={<Save />}
        >
          Save
        </LoadingButton>
      </Box>
    </PageWrapper>
  );
};

export default EditUserSA;
