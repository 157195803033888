/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Box from '../../components/Box';

import Loading from '../../components/Loading';
import SPACES from '../../utils/CONSTS/SPACES';
import Numbered from '../../components/Numbered';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import AddReportModal from '../../components/modals/AddReportModal';
import { useUser } from '../../stateManagement/userStateManager';
import ReportsList from '../../components/ReportsList';

import AddBoxIcon from '@mui/icons-material/AddBox';
import { useUserReports } from '../../stateManagement/ReportsStateManagement';
import useSyncUserReports from '../../utils/hooks/useUserReports';
import DashboardLayout from '../../layouts/DashboardLayout';
import axios from 'axios';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import SuperAdminDashboardLanding from './SuperAdminDashboardLanding';
import useAEAPI from '../../utils/hooks/useAEAPI';
import useAmazon from '../../utils/hooks/useAmazon';
import { LoadingButton } from '@mui/lab';
import { type AmazonProductType } from '../../utils/TYPES';
import DarkModeSwitch from '../../components/DarkModeSwitch';
import useCompareToEbay from '../../utils/hooks/useCompareToEbay';
import LinearProgressWithLabel from '../../components/LinearProgress';
import { log } from 'console';

const functions = getFunctions();

const DashboardLandingPage = (): JSX.Element | null => {
  const { user, permissionStatus, loading } = useUser();

  const { searchAEByImage } = useAEAPI('US');

  const setuserPerm = () => {
    const setUser = httpsCallableFromURL(
      functions,
      firebaseCall('setUserOrganisation'),
    );
    setUser({
      orgId: 'VndAl3MxSLNTpNrG9D9g',
    })
      .then((res) => {
        console.log('setUser', res);
      })
      .catch((err) => {
        console.log('setUser', err);
      });
  };

  useEffect(() => {
    if (!user) return;
    (async () => {
      try {
        console.log('permissionStatus', permissionStatus);

        // const ref = doc(db, `organisations/${user?.organisation}`);
        // const data = await getDoc(ref);
        // console.log('datadatadatadata', data.data(), user?.organisation, data);
      } catch (error) {
        console.log('errorerrorerror', error);
      }
    })();
  }, [user]);

  const token = async () => {
    try {
      const tokens = httpsCallableFromURL(
        functions,
        firebaseCall('getNewTokens2'),
      );
      await tokens({
        type: 'AE_TOKEN',
      });
    } catch (error) {
      console.log('error Token', error);
    }
  };

  if (loading) return <Loading size={100} />;

  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Container fixed>
        <Box minWidth={1000} pt={SPACES.SPACE_L}>
          <Button
            onClick={async () => {
              await token();
            }}
          >
            Set Token
          </Button>
          <DarkModeSwitch />
          <UserDashboard />
        </Box>
      </Container>
    </Paper>
  );
};

const UserDashboard = () => {
  const [addReportModal, setAddReportModal] = React.useState(false);

  const [searchString, setSearchString] = React.useState('');
  const componentWidth = { width: '500px' };

  const { compareProducts, comparedResults, resetList, percentage } =
    useCompareToEbay();
  const { bestSellers, searchProduct, loading } = useAmazon(resetList, 'US');

  const productsToDisplay =
    bestSellers?.length && !comparedResults?.length
      ? bestSellers
      : comparedResults;

  const handleOpen = () => {
    setAddReportModal(true);
  };
  return (
    <Box>
      <Box mt={SPACES.SPACE_S}>
        <Box center flex column>
          <Box mb={SPACES?.SPACE_M}>
            <TextField
              id="outlined-basic"
              label="search"
              variant="outlined"
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              style={componentWidth}
            />
          </Box>
          <LoadingButton
            variant="outlined"
            onClick={() => searchProduct(searchString, 1)}
            loading={loading}
          >
            Search
          </LoadingButton>

          <LoadingButton
            variant="outlined"
            onClick={async () => {
              await compareProducts(bestSellers);
            }}
            loading={loading}
          >
            Compare
          </LoadingButton>
          <Box>
            <LinearProgressWithLabel value={Number(percentage)} />
          </Box>
        </Box>

        <Box mt={SPACES.SPACE_M}>
          {!!productsToDisplay?.length &&
            productsToDisplay.map((item: AmazonProductType, index) => (
              <Box key={item?.asin} row>
                <Box mt={SPACES.SPACE_S} width={'29vw'}>
                  <a href={item.product_url} target="_blank" rel="noreferrer">
                    <img
                      src={item.product_photo}
                      alt="product"
                      loading="lazy"
                      style={{ width: '20vw' }}
                    />
                  </a>
                  <Typography>{item.product_title}</Typography>
                  <Typography variant="h6">{item.product_price}</Typography>

                  <Typography>{item?.delivery}</Typography>
                  <Typography>{item?.sales_volume}</Typography>
                </Box>

                <Box width={'55vw'}>
                  {!!item?.AEProductsPrices && (
                    <CheapestProducts
                      cheapestProducts={item?.AEProductsPrices}
                      isUS={true}
                    />
                  )}
                  {!!item?.cheapestProducts && (
                    <Box>
                      <CheapestProducts
                        cheapestProducts={item?.cheapestProducts}
                        isUS={true}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
};

const CheapestProducts = ({
  cheapestProducts,
  isUS,
}: {
  cheapestProducts: any,
  isUS: boolean,
}) => {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <div>
      <ul
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          listStyle: 'none',
          padding: 0,
        }}
      >
        {!!cheapestProducts?.length &&
          cheapestProducts.map((product: any, index: number) => {
            const isSelected = imageIndex === index;
            console.log('productproductproduct', product);

            return (
              <li
                key={index}
                onClick={() => {
                  setImageIndex(index);
                }}
              >
                <img
                  src={
                    product?.thumbnail ||
                    product?.img?.[0] ||
                    product?.cover ||
                    product?.image?.imageUrl ||
                    product?.product_main_image_url
                  }
                  style={{
                    width: isSelected ? '15vw' : '7vw',
                    borderRadius: 10,
                  }}
                  alt={
                    product?.name || product?.title || product?.product_title
                  }
                />
              </li>
            );
          })}
      </ul>
      <p>
        {cheapestProducts?.[imageIndex]?.name ||
          cheapestProducts?.[imageIndex]?.title ||
          cheapestProducts?.[imageIndex]?.product_title}
      </p>
      <p>
        {!isUS
          ? cheapestProducts?.[imageIndex]?.price?.convertedFromValue
          : cheapestProducts?.[imageIndex]?.price?.value ||
            cheapestProducts?.[imageIndex]?.price ||
            cheapestProducts?.[imageIndex]?.format_price ||
            cheapestProducts?.[imageIndex]?.target_original_price}
      </p>
      <a
        href={
          cheapestProducts?.[imageIndex]?.itemWebUrl ||
          cheapestProducts?.[imageIndex]?.product_detail_url
        }
        target="_blank"
        rel="noreferrer"
      >
        PRODUCT LINK
      </a>
      <h6
        style={{
          color:
            cheapestProducts?.[imageIndex]?.priceDifference > 0
              ? 'green'
              : 'red',
        }}
      >
        {cheapestProducts?.[imageIndex]?.priceDifference}
      </h6>
      <p>
        {cheapestProducts?.[imageIndex]?.sell_points?.[0]?.selling_point_text}
      </p>
    </div>
  );
};

export default DashboardLandingPage;
