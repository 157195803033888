/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import InteractiveEditor from './InteractiveEditor';
import { useReactiveVar } from '@apollo/client';
import {
  ModalStateManager,
  useModalStateManager,
} from '../stateManagement/ModalStateManager';
import {
  Button,
  CircularProgress,
  TextField,
  TextareaAutosize,
  Typography,
  useTheme,
} from '@mui/material';
import COLORS from '../utils/CONSTS/COLORS';
import {
  CheckCircle,
  CheckCircleOutline,
  DriveFileRenameOutline,
  InputOutlined,
} from '@mui/icons-material';
import Box from './Box';
import SPACES from '../utils/CONSTS/SPACES';
import { yellow } from '@mui/material/colors';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

interface ReportFormProps {
  value: any;
  setSectionState: (value: any) => void;
  fromState: any;
  handelUpdateReport: () => void;
  savedFormState: any;
  savingLoading?: boolean;
}

const ReportForm = ({
  value,
  fromState,
  setSectionState,
  handelUpdateReport,
  savedFormState,
  savingLoading,
}: ReportFormProps) => {
  const [isNotSaved, setIsNotSaved] = React.useState(false);

  console.log('fromState', fromState);

  const [modalState, updateModalState] = useModalStateManager();

  const theme = useTheme();

  const [localText, setLocalText] = React.useState(fromState?.text);
  const [localNotes, setLocalNotes] = React.useState(fromState?.notes);

  React.useEffect(() => {
    if (!isNotSaved || (!localText && !localNotes)) return;
    handelUpdateReport();
  }, [fromState?.text, fromState?.notes]);

  React.useEffect(() => {
    setLocalText(fromState?.text);
    setLocalNotes(fromState?.notes);
  }, [fromState?.text, fromState?.notes]);

  React.useEffect(() => {
    setIsNotSaved(
      localText !== savedFormState?.text ||
        localNotes !== savedFormState?.notes,
    );
  }, [localText, localNotes, savedFormState]);

  const ImageButton = (key: string) => (
    <Button
      color="info"
      variant="outlined"
      onClick={() => {
        updateModalState({
          open: true,
          item: value?.value,
          title: value?.title,
          type: 'addImage',
          onAction: (urls: string[]) => {
            setSectionState({
              ...fromState,
              [key]: [...fromState?.[key], ...urls],
            });
          },
        });
      }}
      sx={{
        mr: 1,
      }}
    >
      Add Photo
    </Button>
  );

  const [removeImageI, setRemoveImageI] = React.useState<boolean>(false);
  const removeImage = (urls: string[]) => {
    setSectionState({
      ...fromState,
      images: urls,
    });
    setRemoveImageI(true);
  };

  React.useEffect(() => {
    if (value.title === 'My Client Name')
      console.log(
        'savedFormState IMAGe',
        savedFormState?.images?.length,
        fromState?.images?.length,
      );

    if (
      savedFormState?.images?.length !== fromState?.images?.length &&
      removeImageI
    ) {
      handelUpdateReport();
      setRemoveImageI(false);
    }
  }, [savedFormState, fromState, removeImageI]);

  return (
    <div className="mt-6" id={value?.value}>
      <Box flex row spaceBetween mr={SPACES?.SPACE_M}>
        <Typography variant="h6">{value?.title}</Typography>
        {(fromState?.text || fromState?.notes) && (
          <Box>
            {!isNotSaved ? (
              <CheckCircleOutline
                sx={{
                  color: theme.palette.success.main,
                  ml: 1,
                }}
              />
            ) : (
              <DriveFileRenameOutline
                sx={{
                  color: theme.palette.error.main,
                  ml: 1,
                }}
              />
            )}
          </Box>
        )}
      </Box>

      {value.inputType === 'text' && (
        <Box pt={SPACES?.SPACE_S} mb={SPACES?.SPACE_S} width={'100%'}>
          <Box flex row width={'100%'}>
            <Box column style={{ flex: 4, display: 'flex' }}>
              <TextareaAutosize
                onBlur={() => {
                  setSectionState({ ...fromState, text: localText });
                }}
                value={localText}
                onChange={(e) => {
                  setLocalText(e.target.value);
                }}
                minRows={value?.inputLine}
                style={{
                  marginRight: SPACES?.SPACE_S,
                  backgroundColor: 'transparent',
                  borderColor: theme.palette.secondary.light,
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 10,
                }}
              />
              {/* <InteractiveEditor
                suggestedText={localText}
                setFinalText={(textValue: string) => {
                  setLocalText(textValue);
                }}
                onBlur={(_text: string | undefined) => {
                  console.log('Ive benn called', localText);

                  setSectionState({ ...fromState, text: _text ?? localText });
                }}
                setIsNotSaved={setIsNotSaved}
              />  */}
              <Box row flex mt={SPACES?.SPACE_M} mr={SPACES?.SPACE_S}>
                {value?.addPhoto && ImageButton('images')}
                {value?.response && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      ModalStateManager({
                        ...ModalStateManager(),
                        open: true,
                        item: value?.value,
                        title: value?.title,
                        type: 'main',
                      });
                    }}
                  >
                    Response
                  </Button>
                )}
              </Box>
              <ImageComponent
                urls={fromState?.images as string[]}
                removeImage={removeImage}
                handelUpdateReport={handelUpdateReport}
              />
            </Box>
            <Box column style={{ flex: 2, display: 'flex' }}>
              <TextareaAutosize
                onBlur={(e) => {
                  setSectionState({ ...fromState, notes: localNotes });
                }}
                value={localNotes}
                minRows={value?.inputLine}
                onChange={(e) => {
                  setLocalNotes(e.target.value);
                }}
                style={{
                  marginRight: SPACES?.SPACE_S,
                  backgroundColor:
                    theme?.palette.mode === 'dark' ? '#474534' : '#eeecd8',
                  borderColor: yellow[600],
                  borderWidth: 1,
                  borderRadius: 5,
                  padding: 10,
                }}
              />
              <Box row flex mt={SPACES?.SPACE_M}>
                {value?.addPhoto && ImageButton('notesImages')}

                {value?.response && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      ModalStateManager({
                        ...ModalStateManager(),
                        open: true,
                        item: value?.value,
                        title: value?.title,
                        type: 'note',
                      });
                    }}
                  >
                    Response
                  </Button>
                )}
              </Box>
              <ImageComponent
                urls={fromState?.notesImages as string[]}
                removeImage={removeImage}
                handelUpdateReport={handelUpdateReport}
              />
            </Box>
          </Box>
        </Box>
      )}

      {value?.addPhoto && !value.inputType && ImageButton('images')}

      {!!fromState?.images?.length && !value.inputType && (
        <ImageComponent
          urls={fromState?.images as string[]}
          removeImage={removeImage}
          handelUpdateReport={handelUpdateReport}
        />
      )}

      {value.inputType === 'date' && (
        <div className="p-2">
          <div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  defaultValue={null}
                  value={(localText && moment(localText || null)) || null}
                  disablePast
                  displayWeekNumber
                  onAccept={(newValue) => {
                    setIsNotSaved(true);
                    setSectionState({
                      ...fromState,
                      text: newValue?.format('YYYY-MM-DD'),
                    });
                  }}
                  format="dddd DD MMM YYYY"
                />
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      )}
    </div>
  );
};

const ImageComponent = React.memo(function ImageComponent({
  urls,
  removeImage,
  handelUpdateReport,
}: {
  urls: string[],
  removeImage: (url: string[]) => void,
  handelUpdateReport?: () => void,
}) {
  const [prevURLS, setPrevURLS] = React.useState<any>(urls || []);
  const [loading, setLoading] = React.useState<any>(() => {
    const newState: any = {};
    if (!urls?.length) return newState;
    urls.forEach((_, index) => {
      newState[index] = true;
    });
    return newState;
  });

  const [stateJustAdded, setStateJustAdded] = React.useState<number[]>([0]);
  console.log('stateJustAdded', stateJustAdded);

  React.useMemo(() => {
    if (!urls?.length) return;
    setLoading((prev: any) => {
      const newState: any = {};
      urls.forEach((_, index) => {
        if (index > prevURLS?.length - 1) {
          newState[index] = true;
        }
      });
      return { ...prev, ...newState };
    });
    urls.forEach((_, index) => {
      if (index > prevURLS?.length - 1) {
        setStateJustAdded((prevState) => [...(prevState || []), index]);
      }
    });
  }, [urls]);

  return (
    <>
      <Box row flex>
        {!!urls?.length &&
          urls.map((image: string, index: number) => (
            <Box key={image} pr={SPACES?.SPACE_XS} mt={SPACES?.SPACE_L}>
              {!!loading && loading?.[index] && <CircularProgress />}
              <Box>
                <Button
                  onClick={() => {
                    removeImage(urls.filter((_, i) => i !== index));
                  }}
                >
                  Delete
                </Button>
                <img
                  onLoad={() => {
                    setLoading((prev: any) => ({
                      ...prev,
                      [index]: false,
                    }));
                    setPrevURLS(urls);
                  }}
                  key={index}
                  src={
                    stateJustAdded.includes(index)
                      ? image
                      : image
                          ?.replace('.png', '_800x800.png')
                          ?.replace('.PNG', '_800x800.png')
                          ?.replace('.jpg', '_800x800.png')
                          ?.replace('.jpeg', '_800x800.png')
                  }
                  alt="uploaded"
                  style={{
                    width: 100,
                    marginRight: SPACES?.SPACE_S,
                  }}
                />
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
});

export default React.memo(ReportForm);
