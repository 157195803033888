import React from 'react';
import Box from '../../components/Box';
import { Container, Paper, Typography } from '@mui/material';
import SPACES from '../../utils/CONSTS/SPACES';
import { useNavigate } from 'react-router-dom';
import { useManagedUsers } from '../../stateManagement/ManagedUserStateManager';
import Encryptor from '../../utils/helpers/Encryptor';
import Loading from '../../components/Loading';

const UsersLanding = () => {
  const { users, loading, role, admin } = useManagedUsers();
  const navigate = useNavigate();
  if (loading) {
    return <Loading />;
  }
  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Container fixed>
        <Box minWidth={1000} pt={SPACES.SPACE_L}>
          <Typography variant="h6">Users</Typography>

          {!!admin && <Typography>{admin.displayName}</Typography>}

          <Box>
            {users?.length &&
              users?.map((profile, index) => (
                <Box
                  key={profile?.uid}
                  onClick={() => {
                    const encrypt = Encryptor.encrypt(profile?.uid);
                    navigate(`/user/${encrypt}`);
                  }}
                >
                  <Box row flex vCenter>
                    <Typography>{profile?.displayName}</Typography>
                    <Box ml={SPACES.SPACE_L}>
                      {index === 0 && role === 1 && (
                        <Typography>admin</Typography>
                      )}
                    </Box>
                    <Box ml={SPACES.SPACE_L}>
                      <Typography>{profile?.uid}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default UsersLanding;
