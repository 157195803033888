/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeVar, useReactiveVar } from '@apollo/client';
import { type User } from 'firebase/auth';

export interface UserInfo extends User {
  darkMode?: boolean;
  organisation?: string;
}

interface UserState {
  user: any;
  loading: boolean;
  permissionStatus: {
    superAdmin: boolean,
    admin: boolean,
  };
  updateUser: (data: Record<string, string | boolean | number>) => void;
}

export const UserStateManagerState = makeVar<UserState>({
  user: null,
  loading: false,
  permissionStatus: {
    superAdmin: false,
    admin: false,
  },
  updateUser: () => {},
});

export const useUser = () => {
  const userState = useReactiveVar<UserState>(UserStateManagerState);

  const { user, loading, updateUser } = userState;

  return userState;
};
