import * as React from 'react';
import Box from '../../components/Box';
import { Typography } from '@mui/material';

const LandingPage = () => {
  return (
    <Box center flex>
      <Typography variant="h6">Welcome to My Report AI</Typography>
    </Box>
  );
};

export default LandingPage;
