import * as React from 'react';

import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

interface useAuthProps {
  email: string;
  password: string;
  displayName?: string;
}

const useAuth = () => {
  const auth = getAuth();
  const database = getFirestore();

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState<boolean>(false);

  const login = async ({ email, password }: useAuthProps) => {
    console.log('login', email, password);
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('error', error);
    }
  };

  const register = async ({ email, password, displayName }: useAuthProps) => {
    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );
      const user = userCredential?.user;
      const userRef = doc(database, 'users', user.uid);
      await setDoc(userRef, {
        displayName,
        email,
        uid: user.uid,
        phoneNumber: '',
      });
      setLoading(false);
      navigate('/');
    } catch (error) {}
  };

  const logout = () => {
    signOut(auth).then(() => {
      navigate('/');
    });
  };

  return {
    login,
    register,
    logout,
    loading,
  };
};

export default useAuth;
