/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import useAEAPI from './useAEAPI';
import extractPrice from '../helpers/extractPrice';
import { type AmazonProductType } from '../TYPES';
import useEbayAPI from './useEbayAPI';

async function delay(time: number) {
  return await new Promise((resolve) => setTimeout(resolve, time));
}

const useCompareToEbay = (region: string = 'US') => {
  const isUS = region === 'US';

  const [comparedResults, setComparedResults] = React.useState<any[]>([]);
  const [percentage, setPercentage] = React.useState<number | string>(0);

  const { searchEbay, searchEbayByImage } = useEbayAPI(region);

  const { searchAEByImage } = useAEAPI(region);

  // const findItOnEbay = async (productTitle) => {
  //   return await new Promise((resolve, reject) => {
  //     const data = null;

  //     const xhr = new XMLHttpRequest();
  //     xhr.withCredentials = false;

  //     xhr.addEventListener('readystatechange', function () {
  //       if (this.readyState === this.DONE) {
  //         if (this.status === 200) {
  //           resolve(JSON.parse(this.responseText));
  //         } else {
  //           resolve(null);
  //         }
  //       }
  //     });

  //     xhr.open(
  //       'GET',
  //       `https://ebay-data-scraper.p.rapidapi.com/products?page_number=1&product_name=${productTitle}&region=united%20kingdom`,
  //     );
  //     xhr.setRequestHeader(
  //       'x-rapidapi-key',
  //       '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
  //     );
  //     xhr.setRequestHeader(
  //       'x-rapidapi-host',
  //       'ebay-data-scraper.p.rapidapi.com',
  //     );

  //     xhr.send(data);
  //   });
  // };

  // const findItOnTiktok = async (productTitle) => {
  //   return await new Promise((resolve, reject) => {
  //     const data = null;

  //     const xhr = new XMLHttpRequest();
  //     xhr.withCredentials = false;

  //     xhr.addEventListener('readystatechange', function () {
  //       if (this.readyState === this.DONE) {
  //         if (this.status === 200) {
  //           resolve(JSON.parse(this.responseText));
  //         } else {
  //           console.log('TikTok Error:', this.responseText);
  //           resolve(null);
  //         }
  //       }
  //     });

  //     xhr.open(
  //       'GET',
  //       `https://tiktok-shop-api.p.rapidapi.com/api/shop/search?keyword=${productTitle}&region=GB&count=10&cursor=0`,
  //     );
  //     xhr.setRequestHeader(
  //       'x-rapidapi-key',
  //       '5e03767350msh99c49dea5537532p18783ejsnfe6cda87f482',
  //     );
  //     xhr.setRequestHeader('x-rapidapi-host', 'tiktok-shop-api.p.rapidapi.com');

  //     xhr.send(data);
  //   });
  // };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const compareProducts = async (products: any) => {
    const results = [];

    const totalProducts = products.length;

    let completedProducts = 0;
    for (const product of products) {
      completedProducts++;
      const productTitle = product.product_title;
      const productImage = product?.product_photo;
      const amazonProductPrice = extractPrice(product?.product_price as string);
      setPercentage(((completedProducts / totalProducts) * 100).toFixed(0));
      try {
        // Wait for 0.2 seconds (200 milliseconds) before sending the request
        // await delay(50);

        const ebayProducts: any[] = await searchEbayByImage(
          productImage as string,
        );
        const AEProducts = await searchAEByImage(productImage as string);

        const tikTokProducts: any[] = []; // await findItOnTiktok(productTitle);

        console.log('ebayProducts', ebayProducts);
        console.log('AEProducts', AEProducts);

        const mallBasePrices = ebayProducts?.length
          ? ebayProducts
              .map((ebayProduct) => {
                return {
                  ...ebayProduct,
                  base_price: isUS
                    ? ebayProduct?.price?.value
                    : ebayProduct?.price?.convertedFromValue ||
                      ebayProduct?.price?.value,
                  priceDifference:
                    Number(
                      isUS
                        ? ebayProduct?.price?.value
                        : ebayProduct?.price?.convertedFromValue ||
                            ebayProduct?.price?.value,
                    ) - Number(amazonProductPrice?.[0]),
                };
              })
              .filter(Boolean)
          : [];

        mallBasePrices.sort((a: any, b: any) => a.base_price - b.base_price);

        const cheapestProducts = mallBasePrices.slice(0, 5);
        const priceDifference =
          cheapestProducts?.[0]?.base_price - amazonProductPrice?.[0];

        const AEbasePrices = (AEProducts as any[])?.length
          ? (AEProducts as any[])
              ?.map((aeProduct) => {
                const AEbasePricesPrice = Number(
                  aeProduct?.target_original_price || aeProduct?.base_price,
                );
                return {
                  ...aeProduct,
                  base_price: AEbasePricesPrice,
                  priceDifference:
                    AEbasePricesPrice - Number(amazonProductPrice?.[0]),
                };
              })
              .filter(Boolean)
          : [];

        AEbasePrices.sort((a: any, b: any) => a.base_price - b.base_price);

        const AEProductsPrices = AEbasePrices?.slice(0, 5);
        console.log('AEProductsPrices', AEProductsPrices);

        results.push({
          ...product,
          amazonProductPrice: amazonProductPrice?.[0],
          cheapestProducts,
          AEProductsPrices,
          priceDifference,
        });
      } catch (error) {
        console.error('Error fetching eBay products:', error);
        results.push({
          ...product,
          error: 'Failed to fetch eBay products',
        });
      }
    }

    results.sort((a: any, b: any) => b.base_price - a.base_price);

    setComparedResults(results);
  };

  console.log('comparedResults', comparedResults);

  const resetList = () => {
    setComparedResults([]);
    setPercentage(0);
  };

  return { compareProducts, comparedResults, resetList, percentage };
};

export default useCompareToEbay;
