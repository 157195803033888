/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';
import { useUser } from '../../stateManagement/userStateManager';
import { type IReportDataState } from '../../stateManagement/ReportsStateManagement';
import { useEffect, useState } from 'react';
import { SURVEY_TYPE } from '../CONSTS/RICIS_TERMS';

interface useReportProps {
  id: string | undefined;
}

const db = getFirestore();

const useReport = ({ id }: useReportProps) => {
  const [report, setReport] = useState<IReportDataState | null>(null);
  const [reportBody, setReportBody] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { user } = useUser();
  const getReportInfo = () => {
    setLoading(true);
    const reportRef = doc(db, `users/${user?.uid}/reports/${id}`);
    onSnapshot(reportRef, (document) => {
      console.log('Current document: ', document);
      const type = document.data()?.type;
      const reportType =
        SURVEY_TYPE.find((x) => x.value === type)?.name ?? null;
      const xss: IReportDataState = {
        ...(document.data() as IReportDataState),
        type: reportType,
        id: document?.id,
      };
      setReport(xss);
      setLoading(false);
    });
  };

  const getReport = async () => {
    setLoading(true);
    const reportRef = collection(db, `users/${user?.uid}/reports/${id}/report`);

    const q = query(reportRef, orderBy('dateSaved', 'desc'), limit(1));

    onSnapshot(q, (querySnapshot) => {
      let reportData: any = null;
      querySnapshot.forEach((document) => {
        console.log(document.id, ' => ', document.data());
        reportData = { id: document.id, ...document.data() };
      });
      setReportBody(reportData);
      setLoading(false);
    });
  };

  const revertToPreviousReport = async () => {
    const reportRef = collection(db, `users/${user?.uid}/reports/${id}/report`);

    const q = query(reportRef, orderBy('dateSaved', 'desc'), limit(2));
    const querySnapshot = await getDocs(q);

    const deleteData = querySnapshot.docs.map(async (document) => {
      console.log(document.id, ' => ', document.data()?.dateSaved);

      await deleteDoc(document.ref);
    });

    await Promise.all(deleteData);
  };

  useEffect(() => {
    if (!user) return;
    getReportInfo();
    getReport();
  }, [user]);
  return { report, reportBody, revertToPreviousReport, loading };
};

export default useReport;
