import React from 'react';
import Box from '../../components/Box';
import PageWrapper from '../../components/PageWrapper';
import AuthComponent from '../../components/AuthCompoenet';
import useAuth from '../../utils/hooks/useAuth';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';
import { firebaseCall } from '../../utils/CONSTS/ENDPOINTS';
import { useNavigate } from 'react-router-dom';
import { type UserInfo } from 'firebase/auth';

const functions = getFunctions();

const CreateUserSA = () => {
  const { login, register, loading } = useAuth();
  const navigate = useNavigate();
  const createUser = async ({
    email,
    password,
    displayName,
  }: {
    email: string,
    password: string,
    displayName: string | undefined,
  }) => {
    console.log('email', email);
    console.log('password', password);
    console.log('displayName', displayName);

    const create = httpsCallableFromURL(functions, firebaseCall('createUser'));

    create({ email, password, displayName })
      .then((res) => {
        if ((res?.data as { uid: string })?.uid)
          navigate(`/user/edit/${(res?.data as { uid: string })?.uid}`);
        console.log('create', res?.data);
      })
      .catch((err) => {
        console.log('create', err);
      });
  };

  return (
    <PageWrapper>
      <AuthComponent
        type="Register"
        onClick={(email, password, displayName) => {
          console.log('email', email);
          console.log('password', password);
          createUser({ email, password, displayName });
        }}
      />
    </PageWrapper>
  );
};

export default CreateUserSA;
