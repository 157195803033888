/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  PDFViewer,
  PDFDownloadLink,
  Font,
} from '@react-pdf/renderer';
import useReport from '../../utils/hooks/useReport';
import { useParams } from 'react-router-dom';
import COLORS from '../../utils/CONSTS/COLORS';
import { Height } from '@mui/icons-material';
import { styles } from './styles';
import level2 from '../../RICSFORMAT/level2.json';

interface ReportPreviewProps {
  d?: string;
}

export const PRIMARY_COLOR = '#4d2f69';

Font.register({
  family: 'manrope',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk59E-_F87jxeN7B.ttf',
      fontWeight: 800,
    },
  ],
});

const ReportPreview = (props: ReportPreviewProps) => {
  const { id } = useParams();
  const { reportBody, loading: reportLoading } = useReport({ id });
  console.log('reportBody', reportBody);

  const report = reportBody?.reportData;

  if (!reportBody) return <div>Loading...</div>;
  return (
    <>
      <PDFDownloadLink
        document={<PDFMaker report={report} />}
        fileName="somename2.pdf"
      >
        {({ blob, url, loading, error }) =>
          loading ? 'Loading document...' : 'Download now!'
        }
      </PDFDownloadLink>
      <PDFViewer style={{ width: '100%', height: '100vh' }}>
        <PDFMaker report={report} />
      </PDFViewer>
    </>
  );
};

export default ReportPreview;

const PDFMaker = ({ report }: { report: any }) => {
  return (
    <Document pageLayout="singlePage">
      <Page wrap style={{ paddingBottom: 40 }}>
        <Header />
        <CoverPage report={report} />
        {level2?.sections?.map((section: any) => (
          <>
            <SectionCover
              alphabet={section?.sectionAlphabet}
              title={section?.coverTitle}
              description={section?.description}
              key={section?.coverTitle}
            />

            {section?.extra?.length && <SectionExtra data={section?.extra} />}

            <SectionInfo
              section={section?.sections}
              alphabet={section?.sectionAlphabet}
              title={section?.pageTitle}
              report={report?.[section?.sectionAlphabet]}
            />
          </>
        ))}
        <Footer />
      </Page>
    </Document>
  );
};

const SectionExtra = ({ data }: { data: any[] }) => {
  return (
    <View break>
      <View style={[styles.pageContainer]}>
        {data?.length > 0 &&
          data.map((item: any) => (
            <>
              {item?.type === 'titleAlphabet' && (
                <SectionTitle
                  key={item?.value}
                  alphabet={item?.alphabet}
                  title={item?.value}
                />
              )}
              {item?.type === 'title3' && (
                <View style={styles.mt2} key={item?.value}>
                  <Text style={[styles.textBody, { fontWeight: 800 }]}>
                    {item?.value}
                  </Text>
                </View>
              )}
              {item?.type === 'list' && !!item?.value?.length && (
                <List items={item?.value} key={item?.value} />
              )}
              {item?.type === 'heading_w_bg' && (
                <View
                  style={{
                    backgroundColor: PRIMARY_COLOR,
                    marginTop: 20,
                    paddingVertical: 4,
                    borderRadius: 5,
                  }}
                  key={item?.value}
                >
                  <Text
                    style={[
                      styles.heading3,
                      styles.textWhite,
                      { fontSize: 12, paddingLeft: 20 },
                    ]}
                  >
                    {item?.value}
                  </Text>
                </View>
              )}
              {item?.type === 'body' && (
                <Text
                  key={item?.value}
                  style={[styles.textBodyX, { marginTop: 5 }]}
                >
                  {item?.value}
                </Text>
              )}
            </>
          ))}
      </View>
    </View>
  );
};

const SectionInfo = ({
  section,
  alphabet,
  title,
  report,
}: {
  section: any,
  alphabet: string,
  title: string,
  report: any,
}) => {
  return (
    <View break>
      <SectionTitle alphabet={alphabet} title={title} />
      <View style={[styles.pageContainer]}>
        {section?.length > 0 &&
          section.map((info: any) => (
            <TextField
              key={info?.title}
              title={info?.title ?? ''}
              text={report?.[info?.title]?.text ?? 'text'}
            />
          ))}
      </View>
    </View>
  );
};

// style={styles.container}

const List = ({ items }: { items: any[] }) => (
  <View style={{ marginTop: 5 }}>
    {items.map((item, index) => (
      <View
        key={item}
        style={{ flexDirection: 'row', alignItems: 'flex-start', marginTop: 3 }}
      >
        <Text style={{ marginRight: 8, marginTop: -3 }}>•</Text>
        <Text style={[styles.textBodyX]}>{item}</Text>
      </View>
    ))}
  </View>
);

const SectionCover = ({
  alphabet,
  title,
  description,
  body,
}: {
  alphabet: string,
  title: string,
  description?: string,
  body?: React.ReactNode,
}) => (
  <View break>
    <View
      style={{
        height: '70vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          width: '75%',
        }}
      >
        <Text
          style={[
            styles.heading1,
            { fontSize: 100, fontWeight: 800, color: PRIMARY_COLOR },
          ]}
        >
          {alphabet}
        </Text>
        {!!title && <Text style={styles.heading3}>{title}</Text>}
        {!!description && (
          <Text style={[styles.textBody, styles.mt2]}>{description}</Text>
        )}

        {body && body}
      </View>
    </View>
  </View>
);

const SectionACover = ({ report }: { report: any }) => {
  const sectionA = report?.A;
  return (
    <View>
      <SectionCover
        alphabet="A"
        title="About the inspection and report"
        body={
          <Text style={[styles.textBody, { color: PRIMARY_COLOR }]}>
            This RICS Home Survey – Level 2 has been produced by a surveyor, who
            has written this report for you to use. If you decide not to act on
            the advice in this report, you do so at your own risk.
          </Text>
        }
      />
    </View>
  );
};

const SectionADisclaimer = ({ report }: { report: any }) => (
  <View break>
    <SectionTitle alphabet="A" title="About the inspection and report" />
    <View style={styles.pageContainer}>
      <Text style={[styles.textBody, { fontWeight: 800 }]}>
        As agreed, this report will contain the following:
      </Text>
      <List
        items={[
          'a physical inspection of the property (see The inspection in section L) and',
          'a report based on the inspection (see The report in section L).',
        ]}
      />
      <View
        style={{
          backgroundColor: PRIMARY_COLOR,
          marginTop: 20,
          paddingVertical: 4,
          borderRadius: 5,
        }}
      >
        <Text
          style={[
            styles.heading3,
            styles.textWhite,
            { fontSize: 12, paddingLeft: 20 },
          ]}
        >
          About the report
        </Text>
      </View>
      <Text style={[styles.textBody, { fontWeight: 800, marginTop: 10 }]}>
        We aim to give you professional advice to:
      </Text>
      <List
        items={[
          'make a reasoned and informed decision on whether to go ahead with buying the property',
          'take into account any repairs or replacements the property needs, and',
          'consider what further advice you should take before committing to purchasing the property',
        ]}
      />
      <Text style={[styles.textBodyX, { marginTop: 5 }]}>
        Any extra services we provide that are not covered by the terms and
        conditions of this report must be covered by a separate contract.
      </Text>

      <View
        style={{
          backgroundColor: PRIMARY_COLOR,
          marginTop: 20,
          paddingVertical: 4,
          borderRadius: 5,
        }}
      >
        <Text
          style={[
            styles.heading3,
            styles.textWhite,
            { fontSize: 12, paddingLeft: 20 },
          ]}
        >
          About the inspection
        </Text>
      </View>
      <List
        items={[
          'We only carry out a visual inspection.',
          'We inspect roofs, chimneys and other surfaces on the outside of the building from ground level and, if necessary, from neighbouring public property and with the help of binoculars.',
          'We inspect the roof structure from inside the roof space if there is access (although we do not move or lift insulation material, stored goods or other contents). We examine floor surfaces and under-floor spaces so far as there is safe access to these (although we do not move or lift furniture, floor coverings or other contents). We do not remove the contents of cupboards. We are not able to assess the condition of the inside of any chimney, boiler or other flues. Also, we do not remove secured panels or undo electrical fittings.',
          'We note in our report if we are not able to check any parts of the property that the inspection would normally cover. If we are concerned about these parts, the report will tell you about any further investigations that are needed.',
          'We do not report on the cost of any work to put right defects or make recommendations on how these repairs should be carried out. Some maintenance and repairs we suggest may be expensive.',
          'We inspect the inside and outside of the main building and all permanent outbuildings, but we do not force or open up the fabric of the building. We also inspect the parts of the electricity, gas/oil, water, heating and drainage services that can be seen, but we do not test them. To help describe the condition of the home, we give condition ratings to the main parts (the ‘elements’) of the building, garage and some parts outside. Some elements can be made up of several different parts. ',
          'In the element boxes in sections D, E, F and G, we describe the part that has the worst condition rating first and then briefly outline the condition of the other parts. The condition ratings are described in section B of this report. The report covers matters that, in the surveyor’s opinion, need to be dealt with or may affect the value of the property',
        ]}
      />
    </View>
  </View>
);

const SectionAAboutInspection = ({ report }: { report: any }) => {
  const sectionA = report?.A;
  return (
    <View break>
      <SectionTitle alphabet="A" title="About the inspection" />
      <View style={[styles.pageContainer]}>
        <TextField title="Surveyor’s name" text="Dr. Bardia Suny" />
        <TextField title="Surveyor’s RICS number" text="HJ45235RICS" />
        <TextField title="Company name" text="My Report AI" />
        <TextField title="Date of the inspection" text="12/04/2024" />
        <TextField title="Report reference number" text="235234" />
        <TextField
          title="Related Party Disclosure"
          text={sectionA?.['Related Party Disclosure'].text}
        />
        <TextField
          title="Full address and postcode of the property"
          text={sectionA?.['Full address and post code of the property']?.text}
        />
        <TextField
          title="Weather conditions when the inspection took place"
          text={
            sectionA?.['Weather conditions when the inspection took place']
              ?.text
          }
        />
        <TextField
          title="Status of the property when the inspection took place"
          text={
            sectionA?.[
              'The status of the property when the inspection took place?'
            ]?.text
          }
        />
      </View>
    </View>
  );
};

const SectionBCover = ({ report }: { report: any }) => {
  const sectionA = report?.A;
  return (
    <View>
      <SectionCover
        alphabet="B"
        title="Overall opinion"
        body={
          <View>
            <Text
              style={[styles.textBody, styles.mt1, { color: PRIMARY_COLOR }]}
            >
              This section provides our overall opinion of the property,
              highlights any areas of concern and summarises the condition
              ratings of the different elements of the property. Individual
              elements of the property have been rated to indicate any defects,
              and have been grouped by the urgency of any required maintenance.
              If an element is made up of a number of different parts (for
              example, a pitched roof to the main building and a flat roof to an
              extension), only the part in the worst condition is shown here.
            </Text>
            <View style={styles.mt4}>
              <Text style={[styles.textBody, styles.textBold]}>
                Important note
              </Text>
              <Text
                style={[styles.textBody, styles.mt1, { color: PRIMARY_COLOR }]}
              >
                To get a balanced impression of the property, we strongly
                recommend that you read all sections of the report, in
                particular section K, What to do now, and discuss this with us
                if required
              </Text>
            </View>
          </View>
        }
      />
    </View>
  );
};

const SectionBSummaryOfCondition = ({ report }: { report: any }) => {
  const sectionB = report?.B;
  return (
    <View break>
      <SectionTitle alphabet="B" title="Summary of condition ratings" />
      <View style={[styles.pageContainer]}>
        <TextField
          title="Overall opinion of the property"
          text={sectionB?.['Overall opinion of the property']?.text}
        />
      </View>
    </View>
  );
};

const SectionCCover = ({ report }: { report: any }) => {
  const sectionA = report?.C;
  return (
    <View>
      <SectionCover
        alphabet="C"
        title="About the property"
        body={
          <View>
            <View style={styles.mt4}>
              <Text style={[styles.textBody, styles.textBold]}>
                This section includes:
              </Text>
              <List
                items={[
                  'About the property',
                  'Energy efficiency',
                  'Location and facilities',
                ]}
              />
            </View>
          </View>
        }
      />
    </View>
  );
};

const SectionCEnergyEfficiency = ({ report }: { report: any }) => {
  const sectionC = report?.C;
  return (
    <View break>
      <SectionTitle alphabet="C" title="Energy efficiency" />
      <View style={[styles.pageContainer, { marginTop: 0 }]}>
        <Text style={styles.textBody}>
          We are advised that the property’s current energy performance, as
          recorded in the EPC, is as stated below.
        </Text>
        <Text style={[styles.textBody, styles.mt1]}>
          We have checked for any obvious discrepancies between the EPC and the
          subject property, and the implications are explained to you.
        </Text>
        <View style={styles.mt2} />
        <TextField
          title="Energy efficiency rating"
          text={sectionC?.['Energy efficiency rating']?.text}
        />
        <TextField
          title="Issues relating to the energy efficiency rating"
          text={
            sectionC?.['Issues relating to the energy efficiency rating']?.text
          }
        />
        <TextField
          title="Other services or energy sources (including feed-in tariffs)"
          text={
            sectionC?.[
              'Other services or energy sources (including feed-in tariffs)'
            ]?.text
          }
        />
        <TextField
          title="Other energy matters"
          text={sectionC?.['Other energy matters']?.text}
        />
      </View>
    </View>
  );
};

const SectionCAboutProperty = ({ report }: { report: any }) => {
  const sectionC = report?.C;
  return (
    <View break>
      <SectionTitle alphabet="C" title="About the property" />
      <View style={[styles.pageContainer]}>
        <TextField
          title="Type of property"
          text={sectionC?.['Type of property']?.text}
        />
        <TextField
          title="Approximate year the property was built"
          text={sectionC?.['Approximate year the property was built']?.text}
        />
        <TextField
          title="Approximate year the property was extended"
          text={sectionC?.['Approximate year the property was extended']?.text}
        />
        <TextField
          title="Approximate year the property was converted"
          text={sectionC?.['Approximate year the property was converted']?.text}
        />
        <TextField
          title="Information relevant to flats and maisonettes"
          text={
            sectionC?.['Information relevant to flats and maisonettes']?.text
          }
        />
        <TextField title="Construction" text={sectionC?.Construction?.text} />
      </View>
    </View>
  );
};

const SectionDCover = ({ report }: { report: any }) => {
  const sectionA = report?.C;
  return (
    <View>
      <SectionCover
        alphabet="D"
        title="Outside the property"
        body={<View></View>}
      />
    </View>
  );
};

// Full detail of elements inspected
const SectionDFullDetail = ({ report }: { report: any }) => {
  const sectionD = report?.D;
  return (
    <View break>
      <SectionTitle alphabet="D" title="Full detail of elements inspected" />
      <View style={[styles.pageContainer]}>
        <TextField
          title="Limitations on the inspection"
          text={sectionD?.['Limitations on the inspection']?.text}
        />
        <TextField
          title="D1 Chimney stacks"
          text={report?.D1?.['Chimney stacks']?.text}
        />
        <TextField
          title="D2 Roof Coverings"
          text={report?.D2?.['Roof Coverings']?.text}
        />
        <TextField
          title="D3 Rainwater pipes and gutters"
          text={report?.D3?.['Rainwater pipes and gutters']?.text}
        />
        <TextField
          title="D4 Main walls"
          text={report?.D4?.['Main walls']?.text}
        />
        <TextField
          title="D6 Outside doors (including patio doors)"
          text={report?.D6?.['Outside doors (including patio doors)']?.text}
        />
        <TextField
          title="D7 Conservatory and porches"
          text={report?.D7?.['Conservatory and porches']?.text}
        />
        <TextField
          title="D8 Other joinery and finishes"
          text={report?.D8?.['Other joinery and finishes']?.text}
        />
        <TextField title="D9 Other" text={report?.D9?.Other?.text} />
      </View>
    </View>
  );
};

const SectionCLocationAndFacilities = ({ report }: { report: any }) => {
  const sectionC = report?.C;
  return (
    <View break>
      <SectionTitle alphabet="C" title="Location and facilities" />
      <View style={[styles.pageContainer]}>
        <TextField title="Grounds" text={sectionC?.Grounds?.text} />
        <TextField title="Location" text={sectionC?.Location?.text} />
        <TextField title="Facilities" text={sectionC?.Facilities?.text} />
        <TextField
          title="Local environment"
          text={sectionC?.['Local environment']?.text}
        />
      </View>
    </View>
  );
};

const SectionECover = () => {
  return (
    <View break>
      <SectionCover
        alphabet="E"
        title="Inside the property"
        body={<View></View>}
      />
    </View>
  );
};

const SectionEInsideProperty = ({ report }: { report: any }) => {
  const sectionE = report?.E;
  return (
    <View break>
      <SectionTitle alphabet="E" title="Inside the property" />
      <View style={[styles.pageContainer]}>
        <TextField
          title="Limitations on the inspection"
          text={sectionE?.['Limitations on the inspection']?.text}
        />
        <TextField
          title="E1 Roof structure"
          text={report?.E1?.['Roof structure']?.text}
        />
        <TextField title="E2 Ceilings" text={report?.E2?.Ceilingss?.text} />
        <TextField
          title="E3 Walls and partitions"
          text={report?.E3?.['Walls and partitions']?.text}
        />
        <TextField title="E4 Floors" text={report?.E4?.['']?.text} />
        <TextField
          title="E5 Fireplaces, chimney breasts and flues"
          text={report?.E5?.['Fireplaces, chimney breasts and flues']?.text}
        />
        <TextField
          title="E6 Built-in fittings (built-in kitchen and other fittings, not including appliances)"
          text={
            report?.E6?.[
              'Built-in fittings (built-in kitchen and other fittings, not including appliances)'
            ]?.text
          }
        />
        <TextField
          title="E7 Woodwork (for example, staircase joinery)"
          text={report?.E7?.['Woodwork (for example, staircase joinery)']?.text}
        />
        <TextField
          title="E8 Bathroom fittings"
          text={report?.E8?.['Bathroom fittings']?.text}
        />
        <TextField title="E9 Other" text={report?.E9?.Other?.text} />
      </View>
    </View>
  );
};

// -------------------
// COMPONENTS
// -------------------
const TextField = ({ title, text }: { title: string, text: string }) => (
  <View style={styles.mt2}>
    <View
      style={{
        marginBottom: 10,
      }}
    >
      <Text style={[styles.textBody, styles.textBold]} wrap={false}>
        {title}
      </Text>
    </View>
    <View style={styles.borderField}>
      <Text style={styles.textBody}>{text}</Text>
    </View>
  </View>
);

const SectionTitle = ({
  alphabet,
  title,
}: {
  alphabet: string,
  title: string,
}) => (
  <View style={[styles.row, { alignItems: 'center' }]}>
    {alphabet && (
      <View
        style={[
          styles.center,
          {
            width: 60,
            height: 60,
            backgroundColor: PRIMARY_COLOR,
            opacity: 0.7,
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          },
        ]}
      >
        <Text style={[styles.heading1, styles.textWhite]}>{alphabet}</Text>
      </View>
    )}
    <Text style={[styles.heading3, { marginLeft: 15 }]}>{title}</Text>
  </View>
);

const Header = () => (
  <View fixed>
    <View
      style={[styles.row, { justifyContent: 'flex-end', marginBottom: 10 }]}
    >
      <View>
        <View
          style={{
            backgroundColor: PRIMARY_COLOR,
            width: 200,
            height: 15,
          }}
        />
        <Image
          style={{
            width: 100,
          }}
          src={require('./assets/ricsLogo.png')}
        />
      </View>
    </View>
  </View>
);

const CoverPage = ({ report }: { report: any }) => {
  const sectionA = report?.A;
  return (
    <View
      style={[
        styles.container,
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        },
      ]}
    >
      <View
        style={[
          styles.row,
          {
            paddingHorizontal: 20,
            paddingVertical: 20,
            backgroundColor: PRIMARY_COLOR,
            borderRadius: 5,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1.5,
          }}
        >
          {sectionA?.['Property Front Cover Image']?.images?.[0] && (
            <Image
              style={{
                width: '100%',
              }}
              src={sectionA?.['Property Front Cover Image']?.images?.[0]
                ?.replace('.png', '_800x800.png')
                ?.replace('.PNG', '_800x800.png')
                ?.replace('.jpg', '_800x800.png')
                ?.replace('.jpeg', '_800x800.png')}
            />
          )}
        </View>
        <View
          style={{
            paddingHorizontal: 20,
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          <Text
            style={[
              styles.heading3,
              styles.textWhite,
              styles.textBold,
              {
                opacity: 0.8,
              },
            ]}
          >
            Level 2
          </Text>
          <Text
            style={[
              styles.heading2,
              styles.textWhite,
              styles.mv1,
              styles.textBold,
            ]}
          >
            Your Survey
          </Text>
          <Text
            style={[
              styles.textBody,
              styles.textWhite,
              styles.mv1,
              styles.textBold,
            ]}
          >
            Client Name
          </Text>
          <Text style={[styles.textBody, styles.textWhite]}>
            {sectionA?.['My Client Name']?.text}{' '}
          </Text>
          <Text
            style={[
              styles.textBody,
              styles.textBold,
              styles.textWhite,
              { marginRight: 10 },
              styles.mv1,
              styles.mt3,
            ]}
          >
            Address
          </Text>
          <Text style={[styles.textBody, styles.textWhite]}>
            {sectionA?.['Full address and post code of the property']?.text}
          </Text>
          <Text
            style={[
              styles.textBody,
              styles.textBold,
              styles.textWhite,
              { marginRight: 10 },
              styles.mv1,
              styles.mt3,
            ]}
          >
            Inspection Date
          </Text>
          <Text style={[styles.textBody, styles.textWhite]}>
            Tuesday 16, 2024
          </Text>
        </View>
      </View>
    </View>
  );
};

const Footer = () => (
  <View fixed style={{ position: 'absolute', bottom: 10, marginLeft: 20 }}>
    <View
      style={{
        backgroundColor: PRIMARY_COLOR,
        width: 200,
        height: 2,
        marginBottom: 10,
      }}
    />
    <View style={[styles.row]}>
      <Text
        style={[styles.footerText, { marginRight: 5 }]}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
      {/* <Text fixed style={styles.footerText}>
        RICS Home Survey – Level 2 (survey only)
      </Text> */}
    </View>
  </View>
);
