/* eslint-disable @typescript-eslint/no-empty-interface */
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import SPACES from '../../utils/CONSTS/SPACES';
import { useNavigate } from 'react-router-dom';

interface SuperAdminDashboardLandingProps {}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const adminOptions = [
  {
    name: 'Add New User',
    description: 'Add a new user to the system',
    path: '/createUser',
  },
  {
    name: 'Manage Users',
    description: 'Manage all users in the system',
    path: '/user/manageUsers',
  },
  {
    name: 'Manage Reports',
    description: 'Manage all reports in the system',
    path: '/dashboard/manage-reports',
  },
  {
    name: 'Manage Permissions',
    description: 'Manage all permissions in the system',
    path: '/dashboard/manage-permissions',
  },
];

const SuperAdminDashboardLanding = (props: SuperAdminDashboardLandingProps) => {
  const navigate = useNavigate();
  return (
    <Paper sx={{ minHeight: '100vh' }}>
      <Container fixed>
        <Box pt={10}>
          <Box>
            <Box mb={4}>
              <Typography fontSize={26} fontWeight="bold">
                Welcome to Super Admin Dashboard
              </Typography>
            </Box>
            <Grid container spacing={2}>
              {adminOptions.map((option, index) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                    <Item>
                      <Box
                        height={200}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => {
                          navigate(option.path);
                        }}
                      >
                        <Typography fontSize={18} fontWeight="bold">
                          {option.name}
                        </Typography>
                        <Typography>{option.description}</Typography>
                      </Box>
                    </Item>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default SuperAdminDashboardLanding;
