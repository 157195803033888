/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import ReportForm from './ReportForm';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from './Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import COLORS from '../utils/CONSTS/COLORS';
import SPACES from '../utils/CONSTS/SPACES';
import { useDarkModeState } from '../stateManagement/DarkModeStateManagement';

interface ReportSectionProps {
  title: string;
  section: any;
  sectionState: any;
  setSectionState: (value: any) => void;
  reportState?: any;
  handelUpdateReport: () => void;
  savedSectionState: any;
  savingLoading?: boolean;
  collapseStateSection?: boolean;
  setCollapsedSections?: (value: any) => void;
}

const ReportSection = ({
  title,
  section,
  sectionState,
  handelUpdateReport,
  setSectionState,
  savedSectionState,
  savingLoading,
  collapseStateSection,
  setCollapsedSections,
}: ReportSectionProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);
  const theme = useTheme();

  return (
    <div className="mt-3 mb-3" id={section.sectionAlphabet}>
      <Accordion
        expanded={collapseStateSection}
        onChange={setCollapsedSections}
        style={{
          borderRadius: SPACES?.SPACE_XXS,
          overflow: 'hidden',
        }}
        sx={{
          backgroundColor: 'surface.main',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="info" fontSize="large" />}
          aria-controls="panel1-content"
          sx={{
            backgroundColor: 'surface.dark',
            color: 'white',
            borderRadius: 1,
            overflow: 'hidden',
          }}
        >
          <Box spaceBetween center flex width={'100%'}>
            <Box className="flex items-center">
              <div className="rounded-lg flex items-center px-5">
                <Typography
                  variant="h4"
                  fontWeight={'900'}
                  color={'common.white'}
                >
                  {section.sectionAlphabet}
                </Typography>
              </div>
              <Box>
                <Typography variant="h5" color={'common.white'}>
                  {title}
                </Typography>
              </Box>
            </Box>
            <Box minWidth={'70px'}>{section?.sections?.length} Field</Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            pl: 6,
            pr: 6,
          }}
        >
          <Box>
            {section?.sections?.length &&
              section?.sections.map((formSection: any) => (
                <ReportForm
                  key={formSection?.value}
                  value={formSection}
                  fromState={sectionState?.[formSection?.title]}
                  savedFormState={savedSectionState?.[formSection?.title]}
                  setSectionState={(value: any) => {
                    setSectionState({
                      ...sectionState,
                      [formSection?.title]: value,
                    });
                  }}
                  handelUpdateReport={handelUpdateReport}
                  savingLoading={savingLoading}
                />
              ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ReportSection;
